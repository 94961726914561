let state = {
//APP.

apiUrl: 'https://limedia.co.uk/app',
//apiUrl: 'http://192.168.0.64:8000/app',
//apiUrl: 'http://resseco-booking-env.eba-yktnipv9.eu-west-2.elasticbeanstalk.com/app',
//apiUrl: 'https://atlas.resseco.co.uk/app',

//userAuthServer:'http://192.168.0.64:8000',
//userAuthServer: 'http://resseco-booking-env.eba-yktnipv9.eu-west-2.elasticbeanstalk.com',
userAuthServer:'https://limedia.co.uk',
//userAuthServer: 'https://exzion.giffica.co.uk',
//userAuthServer: 'https://atlas.resseco.co.uk',


isUserAuthentificated:false,
tokenAuthServer:{},
quickLoginForm: false,
//originalRequest:{},

//Auth
estaActive: 0,
estaGroup:[
  {'title':'Babbacombe Inn', 'avatar':'BB', 'theme':'teal'},
  {'title':'Hamiltons Club', 'avatar':'HC', 'theme':'blue'}
],

userActive:{'name':'Logged Out','initials':'XX'},

//refresh
refreshKey:0,

//Theme


//Nav
previousPage:'',

//ConifigFile
configFile:{},


//CAche
axiosCache:{},


//DiaryData
diaryData : [],

//FormData
formData : [],
formDataMaster : [],
currentFormData : [],



  }
 export default state
