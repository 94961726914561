<template>
  <div>
    <div class="result">{{result}}</div>
  <div class="slickey">

    <div v-for="key in letters1" :key="key.key" class="button" @click="clicker(key)">
      {{key}}
    </div>
    <br>
    <div v-for="key in letters2" :key="key.key" class="button" @click="clicker(key)">
      {{key}}
    </div>
    <br>
    <div v-for="key in letters3" :key="key.key" class="button" @click="clicker(key)">
      {{key}}
    </div>



  </div>
    </div>
</template>

<script>
export default {
  name: 'mobileKeyboard',
  data() {
    return {
      result:'text',
      //letters1:['q','w','e','r','t','y','u','i','o','p', 'a','s','d','f','g','h','h','j','k','l','z','x','c','v','b','n','m'],
      letters1:['q','w','e','r','t','y','u','i','o','p'],
      letters2:['a','s','d','f','g','h','j','k','l'],
      letters3:['z','x','c','v','b','n','m'],

    }
  },
  props: {

  },
  methods:{
    clicker(key){
      this.result += key
    }
  },

}
</script>
<style>
.slickey{
  background: #949494;
position: fixed;
width: 100%;
left: 0px;bottom:0px;
z-index: 9999;
}
.slickey .button:hover{
  background: red
}

.slickey .button{

  font-size:16px;
  text-align: center;
padding: 6px;
background: white;
display: inline-block;
min-width: 8%;
padding-top:2%;
padding-bottom:2%;
border-radius: 4px;
margin:3px;
margin-top:1%;
cursor:pointer;
}
</style>
