import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
//import Home from '../views/Home.vue'
import Login from '../views/LoginWindow.vue'
import BookingsTable from '../views/BookingsTable.vue'
import Availability from '../views/Availability.vue'
import TableLayout from '../views/TableLayout.vue'
import Version from '../views/Version.vue'

import keyboard from '../components/keyboard/mobileKeyboard.vue'


//import Demo from '../views/Demo.vue'

Vue.use(VueRouter)

const routes = [
//  {
//    path: '/',
//    name: 'Home',
//    component: Home
//  },http://192.168.0.19:8080/app_current/
{
  path: '/',
  name: 'Home',
  component: BookingsTable
},
{
  path: '/version',
  name: 'Version',
  component: Version
},
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/availability',
    name: 'Availability',
    component: Availability
  },
  {
    path: '/tablelayout',
    name: 'TableLayout',
    component: TableLayout
  },
  {
    path: '/bookings-table',
    name: 'BookingsTable',
    component: BookingsTable
  },
  {
    path: '/keyboard',
    name: 'keyboard',
    component: keyboard
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  //base: '/dev-to-prod/app-v2-legacy/',
    routes
})



router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login','/demo'];
  const authRequired = !publicPages.includes(to.path);
//const isAuth = store.getters.isUserAuthentificated;
  const loggedIn = store.state.isUserAuthentificated;

  if (authRequired && !loggedIn) {
    router.push({'name':'Login'})

  }
  store.state.previousPage = to.path
  if(to.path == '/login') store.state.previousPage = '/'






  next();
})

export default router
