<template>
  <div >

  <singleList :setup="setup" :list="list" @callBack="open"></singleList>

  <singleList :setup="setup1" :list="list2" @callBack="open"></singleList>

</div>

</template>

<script>
import singleList from '@/components/timeline/singleList';

export default {
  name: 'leftLists',
  components:{
    singleList
  },
  props:{


  },
  data() {
    return {
      selectedItem:null,
      setup1:{
        title:'Cancelled Bookings',
        opened:true,
      },
        setup:{
          title:'title',
          opened:false,
        },
      list: [
        {
          id: 1,
          unread:true,
          icon_new: 'mdi-email-outline',
          subtitle: '5x 20/12/2021 Bialo Oko vidi moeze ale nemusiBialo Oko vidi moeze ale nemusi',
          title: 'Peter Fergusson Bialo Oko vidi moeze ale nemusi',
        },
        {
          id: 2,
          unread:false,
          icon_new: 'mdi-email-outline',
          subtitle: '4x 20/12/2021',
          title: 'Joseph Storm',
        },
      ],
      list2: [
        {
          id: 1,
          unread:false,
          icon_new: 'mdi-email-outline',
          subtitle: '5x 20/12/2021 Bialo Oko vidi moeze ale nemusiBialo Oko vidi moeze ale nemusi',
          title: 'Peter Fergusson Bialo Oko vidi moeze ale nemusi',
        },
        {
          id: 2,
          unread:false,
          icon_new: 'mdi-email-outline',
          subtitle: '4x 20/12/2021',
          title: 'Joseph Storm',
        },
      ],
    }
  },
  computed:{

  },


  methods:{
    open(payload){
      console.log(payload);
    },
  },
};
</script>
<style scope>
.v-list{padding:0px;}
.list-text-truncate{
  width:230px;
  display:inline;
}
.list-folder{
  background: white;
  border-radius: 5px;
    margin: 7px;
    border: 1px solid #efefef;
}
.active-v-list{
  background: #e1ffdc;
border-bottom: 1px solid #cfefc9;

}
.border-bottom{
  border-bottom:1px solid #e6e6e6;
}
.v-application--is-ltr .v-list-group--sub-group .v-list-item__action:first-child, .v-application--is-ltr .v-list-group--sub-group .v-list-item__avatar:first-child, .v-application--is-ltr .v-list-group--sub-group .v-list-item__icon:first-child {
    margin-right: 15px;
}
.v-application--is-ltr .v-list-group--sub-group .v-list-group__header {
    padding-left: 18px;
}
.v-application--is-ltr .v-list-group--sub-group .v-list-group__items .v-list-item {
    padding-left: 15px;
}
</style>
