<template>



  <v-dialog v-model="dialog" max-width="640" persistent class="availabilityEdit">
    <v-card>
      <v-progress-linear
      indeterminate
        :active="loading"
      color="primary"
    ></v-progress-linear>
      <v-card-title class="headline">
        <v-icon class="pr-2" color="primary">mdi-table-large</v-icon>
        Schedule
      </v-card-title>

  <v-card-text>
    <v-row>
      <v-col cols="4">
        <v-switch
            v-model="item.checkOnline"
            label="Online"
          ></v-switch>
      </v-col>
      <v-col cols="4">
        <v-switch
            v-model="item.checkInHouse"
            label="In-house"
          ></v-switch>
      </v-col>
    </v-row>

    <h2 class="title mb-2 text-left">
        Choose Days
      </h2>
    <v-chip-group
    class="ae-days"
      v-model="daysSelected"
      column
      multiple
    >
    <v-chip v-for="day in days" :key="day.key"
          filter
          outlined
          active-class="primary--text"
        >{{day}}</v-chip>

  </v-chip-group>

  <v-divider class="mt-3 mb-3"></v-divider>

    <h2 class="title mb-4 text-left">
      Time Range
    </h2>
  <v-row>
    <v-col cols="4">
      <v-text-field
            v-model="time_open"
            label="Opening Time"
            required
          ></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-text-field
            v-model="time_close"
            label="Closing Time"
            required
          ></v-text-field>
    </v-col>
  </v-row>

<v-divider></v-divider>

  <h2 class="title mt-2 mb-4 text-left">
      Available Tables
    </h2>

<div v-for="loc in locations" :key="loc.key">
  <h3 class="text-left primary--text">{{loc.name}}</h3>
    <v-chip-group
    class="ae-tables"
      v-model="item.tablesSelected"
      column
      multiple
    >
    <v-chip v-for="table in loc.tables" :key="table.key"
          active-class="primary--text primary"
          outlined
          pill
        >{{table}}</v-chip>

  </v-chip-group>

</div>




      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text>
          Save
        </v-btn>
        <v-btn color="grey" text @click="dialog = !dialog">
          Close
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>



</template>


<script>


  export default {
    data() {
        return {
          dialog:false,
          loading:false,
          time_open:'10:00',
          time_close:'23:00',
          switch_online:false,
          switch_inhouse:true,
          daysSelected:[0,4],
          days:['Mon','Tue','Wed','Thu','Fri','Sat','Sun'],
          tablesSelected:[0,1,2],
          locations:[
            {name:"Tables",
            tables:[7,9,14,15,16,21,22,23,24,25,26,27,28,29,30,31,32,33,35,36,40,41,42]},

          ],

        }
      },
      name: 'availabilityEdit',

      created() {

      },
      components: {


      },
      props: {

      },
      computed: {


      },
      methods: {
        clicker(){
          console.log(this.item);
        },


      },

  }

</script>


<style>
.ae-days .v-chip{min-width:70px;}
.ae-tables .v-chip{min-width:30px;}
</style>
