<template>
  <div id="app">
    <onlineChecker></onlineChecker>
    <AppWireframe/>
    <quickLoginForm></quickLoginForm>


    <v-snackbar multi-line bottom center :value="updateExists"  :timeout="5000" >
      <v-layout align-center>
        <v-layout column>
          <div style="text-align:center">
            <strong>New Update is Available</strong>
          </div>
          <div style="text-align:center">
            <v-btn text color="#00b29c" @click="refreshApp">
              Install Update
            </v-btn>
          </div>
        </v-layout>
          <v-icon dark large>mdi-alert-decagram</v-icon>
      </v-layout>


  </v-snackbar>

  </div>
</template>




<script>


import AppWireframe from '@/views/AppWireframe';
import interceptorsSetup from '@/_helpers/axios-interceptor'
import quickLoginForm from '@/components/modals/quickLoginForm'
import onlineChecker from '@/components/onlineChecker'


import update from './mixins/update'


interceptorsSetup()


export default {
  name: 'Table',
  data() {
    return {
      version:"2.07",
    }
  },
  mixins: [update],
  created(){

  },
  components:{
    AppWireframe,
    quickLoginForm,
    onlineChecker,

  },
  methods:{




  },
}

</script>
<style>



:root {
  --primary-color: #00b29d;
  --primary-background: #ffffff;
  --primary-background-base: #ebebeb;

  --button-primary-color: #3dbba9;
}

.primary-bcg{
  background: var(--primary-background);}
.primary-bcg-base{

  background: var(--primary-background-base);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}




.cursor-pointer{cursor:pointer}

.noripple .v-ripple__container{
  display:none !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.border-top-grey{border-top:1px solid #e0e0e0;}


/* ********   CUSTOM TAB WINDOWS ******** */

._cus_tabs_win .drop-shaddow{    box-shadow: 0px 0px 2px 0px #d7d5d5}


._cus_tabs_win .tab{margin-left:10px;margin-right:10px;border-radius:5px}
._cus_tabs_win .hover-button:hover{background: #d5d5d5;}
._cus_tabs_win .modal-header{  background: #fafafa; border-bottom: 1px solid #e5e5e5;position: sticky;
    z-index: 999;
    width: 100%;
    top: 0;}
._cus_tabs_win .right-panel-menu-container{background:  #edeaea;}
._cus_tabs_win .right-panel-menu{background: #f2f2f2}
._cus_tabs_win .tabs-panel-active, .tabs-panel-active .v-list-item__avatar .v-icon, .tabs-panel-active .v-list-item__subtitle{color:white!important}
._cus_tabs_win .tabs-panel-notactive:before, .tabs-panel-notactive .v-list-item__avatar .v-icon, .tabs-panel-notactive .v-list-item__subtitle{background:white!important}
._cus_tabs_win .right-panel-button{ background:white;border-radius:4px;margin-top:4px;margin-bottom:6px;}
._cus_tabs_win .right-panel-button:hover{}
._cus_tabs_win  .v-list-item--link:before{border-radius:5px!important;}
._cus_tabs_win .right-panel-button-first{}


._cus_tabs_win .active-btn-toggle{background: var(--primary-color)!important;color:white!important;}
._cus_tabs_win .border-left-1px-grey{border-left:1px solid #ededed;}

</style>
