<template>
<div>
    <v-card  v-for="(item, key) in list"
    elevation="0"
        :key="key" @click="openDiary(item)">
            <v-card elevation="0" class="pa-0 pa-sm-0 mt-1 mb-2" style="border:1px solid #ebebeb">
                <v-row class="text-text-primary" align="center" style="min-height:85px;" no-gutters>
                    <v-col :class="width < 600 ? 'mb-6' : ''" cols="12" md="2" sm="2" style="height:85px;" class="sheet-border-right text-center pt-5 pb-5 pt-sm-0 pb-sm-0">
                        <div v-if="item.type == 'task'">

                            <v-switch color="primary" hide-details class="mt-5 d-inline-block"></v-switch>
                            <div class="mt-n1 text-capitalize c-text-subtitle-3 font-weight-medium">{{ item.type }}</div>

                        </div>
                        <div v-else>
                          <div class="mt-5"
                          @click.stop="toggleTaskDone(item)"
                          v-if="isTask(item.payload)">

                          <v-icon v-if="item.done == 1" color="primary">mdi-check-circle-outline</v-icon>
                          <v-icon v-else >mdi-circle-outline</v-icon>

                          <br>
                          <span class="text-capitalize c-text-subtitle-3 font-weight-medium">
                            <span v-if="item.done == 1" class="primary--text" >Done</span>
                            <span v-else>{{ item.type }}</span>
                          </span>
                          </div>

                            <div v-else class="mt-5">

                            <v-icon>{{ item.icon }}</v-icon><br>
                            <span class="text-capitalize c-text-subtitle-3 font-weight-medium">{{ item.type }}</span>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="10" sm="10" :class="width < 600 ? 'pa-4' : 'pl-5 c-text-body-1 text-center text-sm-left'" class="">
                        <div v-if="item.fields">
                            <v-row >
                                <template v-for="(field, index) in fieldsFilter(item.fields)" >
                                    <template v-if="item.fields.length == 1">
                                        <v-col sm="12" :key="index">
                                            <span class="text-text-primary text-body-2 ">
                                              {{ field.value }} </span>
                                        </v-col>
                                    </template>
                                    <template v-else>

                                        <v-col v-if="field.totalWidth < 12" :sm="field.width" :key="index"
                                            :cols="width < 600 ? 12 : false" class="text-truncate">

                                            <span class="c-text-subtitle-3 text-text-secondary"> {{ field.title }}</span>
                                            <br>
                                            <span class="text-text-primary font-weight-medium c-text-body-1 ">{{ field.value
                                            }}</span>
                                        </v-col>
                                    </template>
                                </template>
                                <div v-if="item.fields.length > 1">
                                    <v-col></v-col>
                                </div>
                            </v-row>

                        </div>
                    </v-col>

                </v-row>

                <v-row no-gutters style="border-top:1px solid #ebebeb;background:#f7f7f7" justify="end" class=" text-caption mt-n2 pa-1 grey--text " >

                <v-col cols="3" sm="6" class="pl-2 pr-2  text-left" v-if="isTask(item.payload) && item.done == 1"><v-icon small color="primary" class="pr-1">mdi-account-check-outline</v-icon>Completed by {{item.done_by_name}}</v-col>
                <v-col cols="3" sm="6" class="pl-2 pr-2 text-right" v-if="item.set_by_name">Created by {{item.set_by_name}}</v-col>
              </v-row>

            </v-card>
    </v-card>
</div>
</template>




<script>
import axios from 'axios';

  export default {
    data () {
      return {
        width:800,

      }
    },
    props: ['list'],
    emits: ['clicked'],

    methods:{

      toggleTaskDone(item){
        if(item.done == 0){item.done_by_name = this.$store.getters.userActive.name}
        if(item.done == 1){item.done_by_name = ''}
        this.sendUpdateDoneTaskAxios({"itemId":item.id,"value":!item.done})
        item.done = !item.done
        //console.log(item.done);
      },

      isTask(payload){
        if(payload == null) return false
        if(payload.formID == 1) return true
        return false
      },

      openDiary(item){
         this.$emit('clicked', item)
      },

      fieldsFilter(list){
        var count = 0

      for (var item of list) {



          item.width = false
          if(item.value){
            if(item.value.length < 9) {
                count += 2
                item.width = 2
            }
            if(item.value.length > 8 && item.value.length < 16 ) {
                count += 3
                item.width = 3
            }
            if(item.value.length > 15) {
                count += 5
                item.width = 5
            }
            item.totalWidth = count
          }
      }

      return list
      },

      sendUpdateDoneTaskAxios(payload){

        let apiUrl = this.$store.state.apiUrl
        axios.post(apiUrl+`/diarytask_done`,payload)
            .then(response => {
              if(response.status == 200 && response.data.status == 'success'){
              //console.log(response.data);
           }else{
             console.warn('Status: '+response.status+' Response: '+ response.data.status );
           }
            })
      },


    },



  }

</script>
