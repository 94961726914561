let getters = {

isUserAuthentificated: state => {
return state.isUserAuthentificated
},

userAuthServer: state => {
return state.userAuthServer
},

tokenAuthServer: state => {
return state.tokenAuthServer
},

estaActive: state => {
return state.estaActive
},

axiosCache: state => {
return state.axiosCache
},

userActive: state => {
return state.userActive
},

userRole: state => {
return state.role
},

diaryData: state => {
return state.diaryData
},
formData: state => {
return state.formData
},

formDataMaster: state => {
return state.formDataMaster
},

currentFormData: state => {
return state.currentFormData
},

configFile: state => {
return state.configFile
},








  }
 export default getters
