<template>


  <v-navigation-drawer
  absolute
  permanent
  width="55px"
  touchless
  >


  <v-menu
          bottom
          min-width="200px"
          rounded
          offset-y
        >


        <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    class="mt-4 mb-2"
                    v-on="on"
                  >
                  <v-avatar color="primary" size="30" >
                       <span class="white--text caption">{{activeAvatar}}</span>
                     </v-avatar>
                </v-btn>

                </template>
 <v-card >

   <v-list >

<v-list-item >
  <v-avatar color="blue-grey lighten-1" size="40" class="mx-auto mt-4">
       <span class="white--text caption">{{userInitials}}</span>
     </v-avatar>
</v-list-item>

<v-list-item>
  <v-list-item-title class="caption blue-grey--text text-lighten-1 text-center">
           {{userName}}
         </v-list-item-title>

</v-list-item>

   </v-list>
 <v-divider></v-divider>


   <v-list dense>
     <v-subheader>ESTABLISHMENT</v-subheader>
        <v-list-item-group
          v-model="estaActive"
          color="primary"
        >
          <v-list-item v-for="esta in establishments" :key="esta.key">
            <v-list-item-icon>
              <v-avatar color="primary" size="30" class="mt-n1">
                   <span class="white--text caption" v-text="esta.avatar"></span>
                 </v-avatar>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="esta.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
 <v-divider></v-divider>

      <v-list  dense>
       <v-list-item @click="logout">
         <v-list-item-icon>
           <v-avatar  size="30" class="mt-n1">
             <v-icon >
         mdi-location-exit
       </v-icon>
              </v-avatar>
         </v-list-item-icon>
         <v-list-item-content>
           <v-list-item-title>Logout</v-list-item-title>
         </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
      <v-list-item class="pa-2">
        <v-list-item-title class="caption blue-grey--text text-lighten-1 text-center mb-n3">
                 Version {{version}}<br>
                 <v-btn text small color="primary" @click="checkForUpdates">Refresh</v-btn>
               </v-list-item-title>
      </v-list-item>
        </v-list>

 </v-card>
  </v-menu>




<template v-for="item in items">
          <div
           v-if="userRole >= item.access"

            :key="item.title"
            class="nb-button"
          @click="goto(item.pageName)"
          >

  <template >
              <v-icon :class="current===item.pageName ? ['primary--text']:['menus--text']">{{ item.icon }}</v-icon>
            <span class="nb-desc" :class="current===item.pageName ? ['primary--text']:['menus--text']" v-text="item.title"></span>
  </template>
    </div>
</template>




  <CustomThemes ref="CustomThemes"></CustomThemes>
</v-navigation-drawer>



</template>
<script>
import router from '@/router'
import CustomThemes from '@/CustomThemes'
import axios from 'axios'


  export default {
    name:"navBarLeft",
    components:{
      CustomThemes
    },
    data () {
      return {

        mainNavbar:true,


        items: [
          { title: 'Home', icon: 'mdi-home-city', pageName: 'Home' , access:1},
        //  { title: 'Factory', icon: 'mdi-factory', pageName: 'Table'  },
        //  { title: 'Bookings', icon: 'mdi-calendar-month-outline', pageName: 'BookingsTable'  },
        //  { title: 'Guest Book', icon: 'mdi-folder-text-outline', pageName: 'GuestBook'  },
        //  { title: 'Tables', icon: 'mdi-table-chair', pageName: 'TableLayout'  },
          { title: 'Settings', icon: 'mdi-cog-outline', pageName: 'TableLayout' , access:2 },
          { title: 'Availability', icon: 'mdi-alarm', pageName: 'Availability' , access:3 },
        //  { title: 'Account', icon: 'mdi-account-circle-outline', pageName: 'Account' ,  access:2 },
        //  { title: 'Settings', icon: 'mdi-cog-outline', pageName: 'Settings'  },
        ],

      }
    },
    watch:{


    },
    computed:{
      version(){
        return this.$parent.$parent.$parent.version
      },
      userName(){
        return this.$store.getters.userActive.name
      },
      userInitials(){
        return this.$store.getters.userActive.initials
      },
      userRole(){
        return this.$store.getters.userActive.role
      },

      current(){
        return this.$route.name
      },
      activeAvatar(){
        return this.establishments[this.estaActive].avatar
      },


      estaActive:{
        get(){
          return this.$store.state.estaActive
        },
        set(val){

          this.$store.dispatch('updateEstaActive',val)
          this.$store.dispatch('refreshKey')
          this.changeTheme(this.$store.state.estaGroup[val].theme)
        }
      },

      establishments(){
        return this.$store.state.estaGroup
      },


    },

    methods:{
      checkForUpdates(){
      window.location = window.location.pathname
      },


      changeTheme(selector){
        //let selector = 'teal'
        this.$vuetify.theme.themes.light = this.$refs.CustomThemes.themes.[selector].light
        this.$vuetify.theme.themes.dark = this.$refs.CustomThemes.themes.[selector].dark
        //this.$vuetify.theme.themes.light.secondary = this.$refs.CustomThemes.themes.[selector].light.secondary
      },




      goto(page){
        router.push({ name: page }).catch(()=>{});
      },
      logout(){

          let apiUrl = this.$store.state.apiUrl
          let that = this
          let payload = {'token':this.$store.state.tokenAuthServer.access_token}
          axios.post(apiUrl+`/logout`, payload)

              .then(response => {
                if(response.status == 200 && response.data.status == 'success'){
                  that.$router.go(that.$router.currentRoute)
             }else{
               console.warn('Status: '+response.status+' Response: '+ response.data );
               that.$router.go(that.$router.currentRoute)
             }
              })
      },
    },
  }
</script>
<style>
.nb-shortcut{background: red;}
.nb-desc{
  font-size: 8px;
  margin-top: 3px;
    display: block;
}
.nb-button{
  min-height: 65px;
  padding-top: 11px;
  padding-left: 3px;
  padding-right: 3px;
}
.nb-button:hover{background: #f1f0f0;cursor:pointer}
.nb-button i{
  width:100%;
  text-align:center;

}
</style>
