<template>
  <div class="userSearch">




<v-list-item class="serchResultItem" @click="closeDialog">

    <v-list-item-avatar >
      <v-icon  color="primary">
    <template v-if="resultSearch == null">mdi-magnify</template>
    <template v-else>mdi-account-circle</template>
  </v-icon>
    </v-list-item-avatar>
    <v-list-item-content v-if="resultSearch == null">
      <v-list-item-title >Guest Surname or Number</v-list-item-title>
    </v-list-item-content>
    <v-list-item-content v-else>

    <v-list-item-title v-if="!resultSearch.display_name">{{resultSearch.name}}</v-list-item-title>
    <v-list-item-title >{{resultSearch.display_name}}</v-list-item-title>


    <v-list-item-subtitle><v-icon x-small class="pr-2" color="grey darken-1">mdi-phone-in-talk-outline</v-icon>{{resultSearch.phone_number}}</v-list-item-subtitle>
    <v-list-item-subtitle><v-icon x-small class="pr-2" color="grey darken-1">mdi-home-city-outline</v-icon>{{resultSearch.origin}}</v-list-item-subtitle>
  </v-list-item-content>

    </v-list-item>





    <v-fade-transition>
      <validation-observer
        ref="observer"
        v-slot="{ invalid }"
      >
      <v-card v-if="dialog" class="popup" >


        <v-card-title >
          <v-text-field class="ml-6"
          :loading="isLoading"
          v-model="search"
          hide-details
          autofocus full-width label="Search for a guest"  prepend-inner-icon="mdi-magnify">

          </v-text-field>

          <v-btn color="primary" class="mt-n8" icon @click="dialog = false
          ">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">

<v-container class="list" v-if="!isAddNew">
  <v-list-item @click="createNewCustomer" class="mt-n3 ml-6 mr-6 primary rounded-lg" v-if="search">
    <v-list-item-avatar >
      <v-icon  color="white">
    mdi-plus
  </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
    <v-list-item-title class="white--text font-weight-bold">Add "{{search}}" as a new customer</v-list-item-title>
  </v-list-item-content>
  </v-list-item>
  <v-list-item-group
        v-model="searchModel"
        color="primary"

      >


          <v-list-item
            v-for="(item, i) in items"
            :key="i"
          >

            <v-list-item-avatar >
              <v-icon  color="primary">
            mdi-account-circle
          </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>

            <v-list-item-title>{{item.name}}</v-list-item-title>

            <v-list-item-subtitle><v-icon x-small class="pr-2" color="grey darken-1">mdi-phone-in-talk-outline</v-icon>{{item.phone_number}}</v-list-item-subtitle>
            <v-list-item-subtitle><v-icon x-small class="pr-2" color="grey darken-1">mdi-home-city-outline</v-icon>{{item.origin}}</v-list-item-subtitle>
          </v-list-item-content>
            </v-list-item>




  </v-list-item-group>





        </v-container>

        <v-container v-if="isAddNew" class="pa-8">
        <div  style="margin:10px;">

          <validation-provider
          v-slot="{ errors }"
        name="Name"
        rules="required|max:27"
        ><v-text-field v-model="newGuest.name" label="First Name" :error-messages="errors"></v-text-field>
        </validation-provider>
        <validation-provider
        v-slot="{ errors }"
      name="Last Name"
      rules="required|max:27"
      >
        <v-text-field v-model="newGuest.surname" label="Last Name" :error-messages="errors"></v-text-field>
        </validation-provider>
        <validation-provider v-slot="{ errors }"
          name="Phone Number"
          rules="required|digits_between:10,11"
        >
        <v-text-field :error-messages="errors" :counter="11" v-model="phone_number" label="Mobile Phone Number"></v-text-field>
        </validation-provider>

        </div>

        </v-container>

        </v-card-text>

      <v-card-actions class="d-flex flex-wrap elevation-5">
          <v-spacer></v-spacer>
          <v-btn color="primary" text v-if="isAddNew" @click="saveNewGuest"  :disabled="invalid">
          Continue
        </v-btn>

          <v-btn color="grey" text v-if="isAddNew" @click="isAddNew = !isAddNew">
            Cancel
            </v-btn>

            <v-btn color="grey" text v-if="!isAddNew" @click="dialog = false">
              Cancel
              </v-btn>

        </v-card-actions>



      </v-card>
      </validation-observer>
    </v-fade-transition>





  </div>
</template>



<script>
import axios from 'axios';
import {  min,max, numeric} from 'vee-validate/dist/rules'
import { required,validate, extend, ValidationObserver, ValidationProvider } from 'vee-validate'

extend('digits_between', {
  async validate(value, { min, max }) {
        const res = await validate(value, `numeric|min:${min}|max:${max}`,)
            return res.valid;
  },
  params: ['min', 'max'],
  message: 'Must be at least 10 digits. (i.e.07xxx xxxxxx) '
});
extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

  extend("min", min);
extend("max", max);
extend("numeric", numeric);

  export default {

    data: () => ({
      invalid:false,
      dialog:false,
      isLoading: false,
      items: [],
      searchModel: null,
      resultSearch:null,
      search: null,
      tab: null,
      timeout: null,
      isAddNew:false,
      newGuest:{"name":""},
      phone_number:null,

    }),

    components: {
      ValidationProvider,
      ValidationObserver,
    },

    watch: {

      phone_number(val){
        if(val.includes("+44")){
          this.phone_number = (val.replace("+44","0"))
        }

        this.newGuest.phone_number=this.phone_number
      },


      searchModel(val){
        //console.log(this);
        this.resultSearch = this.items[val]
        this.dialog = false

      },

      search (val) {

        if(isNaN(val)){
          this.newGuest.name=""
          this.newGuest.phone_number=""
          this.newGuest.surname = this.capitalizeFirstLetter(val)
        }else{
          this.newGuest.name=""
          this.newGuest.surname=""
          this.newGuest.phone_number = val
        }
        if (this.timeout) clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
          this.makeSearch(val)
        }, 400);


      },
    },
    methods:{

      saveNewGuest(){
        //console.log(this);
        this.dialog = false
        this.resultSearch={"id":null,
        "name":this.newGuest.name,
        "display_name": this.newGuest.surname + ' ' + this.newGuest.name,
        "surname": this.newGuest.surname,
        "seated":false,
        "origin": "New Record",
        "phone_number": this.newGuest.phone_number,
        "text": ""}
      },
       capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
},
      closeDialog(){
        this.dialog = true
        this.isAddNew = false
      },
      createNewCustomer(){
      this.isAddNew = !this.isAddNew
      this.phone_number = this.newGuest.phone_number
      },

      makeSearchresults(val){
        let result = []
        for (var i = 0; i < val.length; i++) {
          let item = {'id':val[i].id,
                      'name':val[i].surname +' '+val[i].name,
                      'origin':val[i].origin,
                      'phone_number':val[i].phone_number,
                      'text':val[i].name+' ('+val[i].phone_number+')'}

            //  item.push({'text':'aa'})
          result.push(item)
        }
        return result
      },
      makeSearch(val) {

        //if(this.searchInObj(this.items,val)) return
        this.filterResults(this.items,val)

        if(val){if (val.length < 3) return }

        this.isLoading = true
        //this.items = []
          let apiUrl = this.$store.state.apiUrl
        // Lazily load input items
        //fetch('https://api.coingecko.com/api/v3/coins/list')
         axios.get(apiUrl+'/guestQuickSearch?value='+val)
          //.then(res => res..clone())
          .then(res => {
            if(res.data.items) this.items = this.makeSearchresults(res.data.items)
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
      },

       filterResults(obj,searchKey){
         let filteredItem = []
         if(searchKey){
        for (var i = 0; i < obj.length; i++) {
          if (obj[i].text.toLowerCase().indexOf(searchKey.toLowerCase()) !== -1) {
          filteredItem.push(obj[i])
          }
        }
      }
      this.items = filteredItem
      },

    },
    computed:{

    },
  }
</script>


<style>
.v-list-item__title{text-align:left;}
.v-list-item__subtitle{text-align:left;}
.userSearch .list{background: white;}
.userSearch .popup{
  position: absolute;
width: 100%;
background: white;
left: 0px;
top: 0px;
height: 100%;
z-index: 9;
}
.userSearch .v-card {
  display: flex !important;
  flex-direction: column;
}
.userSearch .serchResultItem{
  border: 1px solid #949494;
border-radius: 6px;
}

.userSearch .v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>
