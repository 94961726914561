<template>

  <v-dialog
    v-model="dialog"
    max-width="350"
  >

    <v-card>
      <v-progress-linear
      indeterminate
        :active="isLoading"
      color="primary"
    ></v-progress-linear>

    <v-card-title class="headline" v-if="isLoading">
    <v-icon class="pr-2" color="primary">mdi-timer-sand</v-icon>
    Loading...
    </v-card-title>

      <v-card-title class="headline" v-else>
        <v-icon class="pr-2" color="primary">mdi-clock</v-icon>
    Time
      </v-card-title>


      <v-card-text>
        <v-container class="text-h5">
          <v-btn color="primary" icon @click="timeDown">
        <v-icon >mdi-arrow-left-drop-circle-outline</v-icon>
        </v-btn>
      {{tempTime.time}}
      <v-btn color="primary" icon @click="timeUp">
      <v-icon>mdi-arrow-right-drop-circle-outline</v-icon>
      </v-btn>
      </v-container>
      </v-card-text>


    <v-card-actions class="d-flex flex-wrap elevation-5">



      <v-btn
      v-if="$parent.moveAction == 'move'"
        color="primary"
        text
        :disabled="isLoading"
        @click="moveItemAxios"
      >
      <v-icon color="primary" small class="pr-1"
      >mdi-arrow-right</v-icon>
        Move
      </v-btn>

      <v-btn
      v-if="$parent.moveAction == 'copy'"
        color="primary"
        text
        :disabled="isLoading"
        @click="pasteItemAxios"
      >
      <v-icon color="primary" small class="pr-1"
      >mdi-content-paste</v-icon>
        Paste
      </v-btn>


<v-spacer></v-spacer>
      <v-btn
        color="grey"
        text
        @click="dialog = false"
      >
        Close
      </v-btn>
    </v-card-actions>




    </v-card>
  </v-dialog>



</template>




<script>

export default {
  data() {
    return {

      dialog:false,
      isLoading:false,
      time:{"time":"00:00"},
      tempTime:{"time":"00:00"},
      newTime:{"time":"00:00"},

    }},
  name: 'timelineMoveItem',
  created() {

    },
    watch:{
      dialog(){
        // this is to delay animation on closing
        let that = this
        let delay = 0
        if(this.dialog == false) delay = 200
        setTimeout(function(){
          that.tempTime = JSON.parse(JSON.stringify(that.time))
        }, delay);
      }
    },
  components: {


  },
  props:{

  },
  computed: {


  },
  methods:{
    openEditDialog(){
      this.newTime = this.tempTime
      this.$parent.timelineItemEditorDialog = true
      this.dialog = false

    },
    moveItemAxios(){
      this.$parent.sendMoveItemAxios(this.tempTime)
    },
    pasteItemAxios(){
      this.$parent.sendPasteItemAxios(this.tempTime)
    },
    timeUp(){
    this.tempTime.time = this.addTime(this.tempTime.time,5);
    },
    timeDown(){
    this.tempTime.time = this.addTime(this.tempTime.time,-5);
    },

    addTime(time, add){
  let hhmm = time.split(':')
  let totalMinutes = +( hhmm[0] * 60 ) + +hhmm[1] + +add
  let newhh = ("0"+Math.floor(totalMinutes / 60)).slice(-2);
  let newmm = ("0"+totalMinutes % 60).slice(-2);
  return newhh + ':' + newmm
    },
  },
}
</script>


<style>
</style>
