<template>
<div>



<v-card
 v-for="(group, g) in formsData" :key="g"
style="border:1px solid #ebebeb; background: #fff;"
elevation="0"
class="pa-0 mt-1 mb-2 text-left">
<h5 class="pl-2 pt-2 pb-1 mb-4 text-uppercase  grey--text grey lighten-2">{{ group.group_name }}</h5>
  <v-row no-gutters class="ma-1">

    <v-col cols="12" :sm="item.col_size" v-for="(item, i) in group.items" :key="i"
    class="pl-2 pr-2"
    >

    <v-select v-if="item.type == 'select'"
          v-model="item.value"
          :items="items"
          item-text="state"
          item-value="abbr"

          :label="item.title"
          persistent-hint
          return-object
          single-line
          outlined
        ></v-select>

    <v-textarea v-if="item.type == 'textarea'"
    :label="item.title"
    outlined
    v-model="item.value"
    class="kustomForm"
    >
    </v-textarea>

      <v-text-field
      v-if="item.type == 'text-field'"
      outlined
      class="kustomForm"
        v-model="item.value"
        :label="item.title"
      ></v-text-field>

    </v-col>
  </v-row>
</v-card>
</div>
</template>

<script>

export default {

  components:{

  },


   props:['formsData'],

  data () {
    return {
      select:[2],
      items:["lorna Polna","Jak Pak","Los Dos"]
    }
  },

  methods:{
    findItemInObjbyId(obj,id){
      return obj.findIndex(x => x.id === id);
    }
  }
}
</script>



<style>
.kustomForm > div > div > .v-text-field__slot input,
.kustomForm > div > div > .v-text-field__slot textarea
 {
   font-size: 1em;
   font-weight: 500
 }
</style>
