<template>
  <div class="home">
    <h1>Version Update</h1>



  </div>
</template>

<script>
// @ is an alias to /src



export default {
  name: 'version',
  components: {

  },
  computed:{

  },
  methods:{

  },
}
</script>
