<template>
<v-dialog v-model="dialog" max-width="850">
  <div class="modal-header">
    <v-container class="grey lighten-5 pa-3 noselect">
      <v-row>
        <v-col cols="10" sm="11" class="pt-4 text-left text-h6">

          <v-row class="" align="center" dense>
            <v-col sm="1" cols="2" class="text-center">
              <v-icon color="primary">mdi-account-circle-outline</v-icon>
            </v-col>
            <v-col sm="11" cols="10" class="pa-0 text-left text-h6">
              <div class="mb-n2 grey--text text-caption">Customer</div>
              <div class="font-weight-medium text-h6">{{data.surname}} {{data.name}}</div>
            </v-col>

          </v-row>
        </v-col>

        <v-col cols="2" sm="1" class="pa-0 ma-0" style="border-left:1px solid #e6e6e6;">
          <div v-ripple class="hover-button pa-5 cursor-pointer" @click="dialog = false">
            <v-icon color="primary">mdi-close</v-icon>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>



  <v-container class="pa-0 mt-0">
    <v-row no-gutters>



      <v-col cols="12" sm="9" class="ma-0 pa-4 primary-bcg-base">




        <div v-if="currentTab==0" class=" ml-2 mr-2 noselect">
          <div class="rounded primary-bcg drop-shaddow">


            <v-container class="ma-0 pa-0">
              <v-row class="pt-2 pb-2 primary-bcg mr-2 ml-2 mb-1 mt-2" justify="center" align="center" dense>

                <v-col sm="1" cols="2" class="pt-3">
                  <v-icon class="pr-1 pl-1" color="primary">mdi-calendar-text-outline</v-icon>
                </v-col>
                <v-col sm="11" cols="10" class="pt-4 text-left">
                  <div class="mb-n2 grey--text text-caption">Booking Reference</div>
                  <div class="font-weight-medium text-h6">GGLG30</div>
                </v-col>

              </v-row>

            </v-container>

            <v-divider></v-divider>

            <v-container class="pa-0 ma-0 text-body-2">
              <v-row class="primary-bcg ml-2 mr-2 mt-2 mb-0">

                <v-col cols="6" sm="4" class="text-center">
                  <v-icon color="primary">mdi-alpha-t-box-outline</v-icon><br>
                  <div>{{data.loca}}</div>
                  <div class="mt-n1 text-h6 black--text">#{{data.table}}</div>
                </v-col>

                <v-col cols="6" sm="2" class="text-center">
                  <v-icon color="primary">mdi-account-multiple-outline</v-icon><br>
                  <span>Guests</span>
                  <div class="mt-n1 text-h6 black--text">{{data.guests}}</div>
                </v-col>

                <v-col sm="3" class="text-center">
                  <v-icon color="primary">mdi-clock-outline</v-icon><br>
                  <span>Time</span>
                  <div class="mt-n1 text-h6 black--text">{{data.time}}</div>
                </v-col>

                <v-col sm="3" class="text-center">
                  <v-icon color="primary">mdi-clock-outline</v-icon><br>
                  <span>Duration</span>
                  <div class="mt-n1 text-h6 black--text">{{data.dura}}"</div>
                </v-col>

              </v-row>
            </v-container>
            <v-divider></v-divider>

            <v-container class="ma-0 pa-0">
              <div class="ma-2 pt-3 pb-3 primary-bcg">
                <template v-for="(tags,index) in data.tags">
                  <v-chip outlined class="mb-2 mr-1 text-body-1" :key="index">
                    <v-icon left small>
                      {{tags.icon}}
                    </v-icon>
                    {{tags.value}}
                  </v-chip>
                </template>
              </div>
            </v-container>
          </div>

          <div class="rounded primary-bcg drop-shaddow">

            <v-container class="ma-0 pa-0 mb-2">
              <v-row align="center" class="ml-2 mr-2 mt-0 rounded primary-bcg" justify="center">
                <v-col sm="9" cols="7" class="pl-6 text-left text-body-1">
                  <span class="cursor-pointer text-decoration-underline" @click="clickSeatedToggle">
                    Customer Seated / Arrived
                  </span>
                </v-col>
                <v-col sm="3" cols="5" class="text-right">
                  <v-btn-toggle mandatory v-model="seatedToggle" active-class="active-btn-toggle" color="primary">
                    <v-btn>
                      NO
                    </v-btn>
                    <v-btn>
                      YES
                    </v-btn>
                  </v-btn-toggle>
                </v-col>

              </v-row>
            </v-container>
          </div>
        </div>

        <div v-if="currentTab==1">
          <v-container>
            <v-row align="center" class="ml-2 mr-2 mt-0 rounded primary-bcg">
              <v-col><br>sss<br>sss<br>sss<br>sss<br>sss<br><br></v-col>
            </v-row>
          </v-container>
        </div>

        <div v-if="currentTab==2">
          <v-container>
            <v-row align="center" class="ml-2 mr-2 mt-0 rounded primary-bcg">
              <v-col><br>dddd<br>dd<br>sss<br>sss<br>sss<br><br></v-col>
            </v-row>
          </v-container>
        </div>

        <br>
      </v-col>

      <v-col cols="12" sm="3" class="ma-0 pa-0 grey lighten-3" style="background:white!important">

        <v-list two-line>
          <v-list-item-group v-model="currentTab" active-class="primary tabs-panel-active">
            <template v-for="(item, index) in rightMenuItems">
              <v-list-item :disabled="index == currentTab" :ripple="false" :key="index" @click="tabClick(item.action)" class="right-panel-button">
                <v-list-item-avatar>
                  <v-icon color="primary">{{item.ico}}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{item.title}}</v-list-item-title>
                  <v-list-item-subtitle class="text-caption">{{item.desc}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>

      </v-col>

    </v-row>
  </v-container>

</v-dialog>
</template>




<script>
//hhreKJ67ER

export default {
  data() {
    return {

      currentTab:0,
      selected:[],
      primary:'red',
      rightMenuItems:[
        {"title":"Booking","desc":"General info","ico":"mdi-account-multiple-outline","action":"test"},
        {"title":"Edit","desc":"Edit booking","ico":"mdi-lead-pencil","action":"test2"},
        {"title":"Log","desc":"Booking info","ico":"mdi-file-document-outline","action":"close"},
        {"title":"Move","desc":"Change table or time","ico":"mdi-drag-variant","action":"test3"},
        {"title":"Copy","desc":"Duplicate item","ico":"mdi-content-copy"},
        {"title":"No Show","desc":"Mark as no-show","ico":"mdi-eye-off"},
        {"title":"Remove","desc":"Delete or cancel","ico":"mdi-trash-can-outline"},

      ],
      dialog:false,
      data:[],
      line:[],
    TimelineItemDetailsData:[]
    //dialog: true,

    }},
  name: 'TimelineItemPreview',
  created() {

    },
  components: {


  },
  props:{

  },
  computed: {
    menu_cols(){
      if(this.expandMenu) return 9
      return 12
    },
    seatedToggle:{
      get(){
        if(this.data.tags){
        let val = this.data.tags[0].seated
        if(val == true) return 1
        }
        return 0
      },
      set(val){

        this.data.tags[0].seated = false
        if(val == 1) this.data.tags[0].seated = true
        this.updateTags()
      }
      },
  },
  methods:{
    tabClick(action){

      if (action === "test3") {this.currentTab = 2}
      if (action === "test2") {this.currentTab = 1}
      if (action === "test") {this.currentTab = 0}
      if (action === "close") {this.dialog = false}
    },

    clickSeatedToggle(){
      this.data.tags[0].seated=!this.data.tags[0].seated
      this.updateTags()
    },
    updateTags(){
      let tags = {'system':{'seated':this.data.tags[0].seated}}
      let payload = {'time':this.data.timestamp,
                      'itemId': this.data.id,
                    'tags':tags}

      this.$parent.sendChangeTagsAxios(payload);


    },

    editDialog(){

      this.dialog = false
      this.$parent.timelineItemEditorDialog = true
      this.$parent.$refs.timelineItemEditor.isEditingMode = true

      this.$parent.$refs.timelineItemEditor.isSeated = false


      this.$parent.currentSelectedTableSeats = {'seats_min':this.line.seats_min,'seats_max':this.line.seats_max,}
      //this.$parent.$refs.timelineItemEditor.data.guests = this.createSelected(1,4,6)


      this.$parent.getItemDataAxios(this.data)

    },
moveItem(data,action){

  this.dialog = false
  this.$parent.moveAction = action
  this.$parent.moveMode = true
  this.$parent.editMode = true
  this.$parent.currentOpenItemId = data.id
  this.$parent.currentSelectedOldTime = data.timestamp



},



  },


}
</script>


<style scoped>
.drop-shaddow{    box-shadow: 1px 1px 5px 1px #dadada;}
.tab{margin-left:10px;margin-right:10px;border-radius:5px}
.hover-button:hover{background: #d5d5d5;}
.modal-header{    border-bottom: 1px solid #e5e5e5;}
.tabs-panel-active, .tabs-panel-active .v-icon, .tabs-panel-active .v-list-item__subtitle{color:white!important}
.xxtabs-panel-active:before{    position: absolute;
    opacity:0.4;
    content: "";
    width: 0px;
    height: 0px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #ffffff;
    top: 38%;

    background: transparent;}
.right-panel-button{border-bottom:1px solid #eeeeee;}
.active-btn-toggle{background: var(--primary-color)!important;color:white!important;}
.border-left-1px-grey{border-left:1px solid #ededed;}
</style>
