let actions = {


//  updateBookingsData({commit}, payload){
//    commit('updateBookingsData', payload)
//  },

updateisUserAuthentificated({commit}, payload){
commit('isUserAuthentificated', payload)
},

updateQuickLoginForm({commit}, payload){
commit('quickLoginForm', payload)
},

updateUserActive({commit}, payload){
commit('userActive', payload)
},

updateEstaGroup({commit}, payload){
commit('estaGroup', payload)
},

updateDiaryData({commit}, payload){
commit('diaryData', payload)
},

updateFormsData({commit}, payload){
commit('formData', payload)
},
updateFormsDataMaster({commit}, payload){
commit('formDataMaster', payload)
},
updateCurrentFormData({commit}, payload){
commit('currentFormData', payload)
},

updateConfig({commit}, payload){
commit('configFile', payload)
},





updateAxiosCache({commit}, payload){
  let addNew = {'url':payload.config.url,
                'data': payload.data,
                'payload':payload}
commit('axiosCache', addNew)
},



updatetokenAuthServer({commit}, payload){
commit('tokenAuthServer', payload)
},

updateEstaActive({commit}, payload){
commit('estaActive', payload)
},

refreshKey({commit}){
commit('refreshKey')
},



  }
 export default actions
