<template>
  <div>
    <v-overlay
          :value="loading"
          opacity="0.9"
          color="#f9f9f9"
        >
    <div class="loader">
      <v-progress-circular
          indeterminate
            color="primary"

          size="64"
        ></v-progress-circular>
    </div>
    </v-overlay>
  <v-container >
    <v-row >
      <v-col cols="6">
    <v-btn @click="addNewLoca" class="mr-2" color="primary" outlined ><v-icon>mdi-plus</v-icon>Add Section</v-btn>

          <v-btn @click="addNewTable" color="primary" outlined ><v-icon>mdi-plus</v-icon> Add Table</v-btn>

      </v-col>
    <v-col cols="12" v-for="location in tableLayout" :key="location.key">
      <v-card

      class="ma-2 text-left"
      min-width="200"
    outlined
    tile  >


    <v-sheet
        elevation="0"


      >

      <v-sheet
         class="pa-3 primary--text blue-grey lighten-5 body-1 text-left"
         dark
       >
       <v-row>
         <v-col cols="6" class="text-left">
           [{{location.position}}] {{location.locationName}}
         </v-col>
          <v-col cols="6" class="text-right">
            <v-btn outlined icon small color="primary" @click="editLoc(location)"><v-icon small>mdi-pencil-outline</v-icon></v-btn>
          </v-col>
       </v-row>








     </v-sheet>

     <template v-for="table in location.tables">
     <v-chip
       @click="openTable(table)"
     :key="table.key"
     outlined
     ripple
     label
     width="300"
       class="ma-2"
       color="blue-grey lighten-2"
       text-color="blue-grey"
     >
       <v-avatar
         left
         tile
         class="blue-grey lighten-2 white--text text-center"
       >
         {{table.table_no}}
       </v-avatar>
       Seats: {{table.seats_min}}-{{table.seats_max}}
     </v-chip>
</template>

</v-sheet>
</v-card>
</v-col>
</v-row>
</v-container>
<tableLayoutTable ref="tableLayoutTable"></tableLayoutTable>
<tableLayoutLocations ref="tableLayoutLocations"></tableLayoutLocations>

</div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import tableLayoutTable from '@/components/modals/tableLayoutTable';
import tableLayoutLocations from '@/components/modals/tableLayoutLocations';




export default {
  name: 'tablesLayout',
  components:{
    tableLayoutTable,
    tableLayoutLocations
  },
  data(){
    return{
      loading:false,
      tab:[],
      tableOptions:null,
      locationItems:[],
      tableLayout:[
        {
            "locationName": null,
            "locationId": null,
            "tables": [
              {
                "table_id": null,
                "table_no":null,
                "seats_min":null,
                "seats_max": null,
                "location_id":null,
              }
            ]
          }
      ],

    }
  },
  props:{

  },
  created(){
    this.fetchData()
  },

  watch:{

  },
  methods:{
    resetTable(){
      this.$refs.tableLayoutTable.tableData = {
        "table_id": 0,
        "table_no":null,
        "seats_min":null,
        "seats_max": null,
        "location_id":null,
      }
    },
    addNewTable(){
      this.$refs.tableLayoutTable.tableDialog  = true
      this.resetTable()
      this.$refs.tableLayoutTable.locItems  = this.locationItems
    },

    openTable(val){

      this.$refs.tableLayoutTable.tableDialog  = true
      this.$refs.tableLayoutTable.tableData  = val
      this.$refs.tableLayoutTable.features  = this.tableFeatures
      this.$refs.tableLayoutTable.tableFeaturesSelected = this.convertSelectedFeatures(val.options)
      //console.log((this.convertSelectedFeatures(val.options)));

      this.$refs.tableLayoutTable.locItems  = this.locationItems


    },
    editLoc(val){
      this.$refs.tableLayoutLocations.dialog  = true
      this.$refs.tableLayoutLocations.data.locationName  = val.locationName
      this.$refs.tableLayoutLocations.data.locationId  = val.locationId
        this.$refs.tableLayoutLocations.data.position  = val.position
    },
    addNewLoca(){
      this.$refs.tableLayoutLocations.dialog  = true
      this.$refs.tableLayoutLocations.data.locationName  = null
      this.$refs.tableLayoutLocations.data.locationId  = 0
      this.$refs.tableLayoutLocations.data.position  = 0
    },


    fetchData(){
      let apiUrl = this.$store.state.apiUrl
      this.loading = true
      let that = this
      axios.post(apiUrl+`/table-layout`,{'viewTime':'time'})

          .then(response => {
            if(response.status == 200 && response.data.status == 'success'){
              that.tableLayout = response.data.tableLayout
              that.locationItems = response.data.locations
              that.tableFeatures = response.data.options

           that.loading = false
         }else{
           console.warn('Status: '+response.status+' Response: '+ response.data.status );
         }
          })

    },

    updateDate(data){
      let apiUrl = this.$store.state.apiUrl
      this.loading = true
      let that = this
      let payload = {'location_id':data.location_id,
                      'table_id':data.table_id,
                      'table_no':data.table_no,
                      'options':data.options,
                      'seats_min':data.seats_min,
                      'seats_max':data.seats_max,}
      axios.put(apiUrl+`/table-layout`, payload)

          .then(response => {
            if(response.status == 200 && response.data.status == 'success'){
              that.tableLayout = response.data.tableLayout
              that.locationItems = response.data.locations
              that.tableFeatures = response.data.options
           that.loading = false
         }else{
           console.warn('Status: '+response.status+' Response: '+ response.data.status );
         }
          })

    },

    convertSelectedFeatures(feat){
      //var feat = this.tableData.options
      var filtered = []
      if(feat){
      var featjson = JSON.parse(feat)
      for (const [key, value] of Object.entries(featjson)) {
      //console.log(`${key}: ${value}`);
        if(value == 1 && key.charAt(0) == 'f') filtered.push(parseInt(key.substring(1)))
      }}
      return(filtered);
    },

    updateLocation(data){
      let apiUrl = this.$store.state.apiUrl
      this.loading = true
      let that = this
      let payload = {'locationId':data.locationId,
                      'locationName':data.locationName,
                      'position':data.position,
    }
      axios.put(apiUrl+`/table-layout-location`, payload)

          .then(response => {
            if(response.status == 200 && response.data.status == 'success'){
              that.tableLayout = response.data.tableLayout
              that.tableFeatures = response.data.options
              that.locationItems = response.data.locations

           that.loading = false
         }else{
           console.warn('Status: '+response.status+' Response: '+ response.data.status );
         }
          })

    },

  },
  computed:{

  },
}
</script>
