import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);




export default new Vuetify({

      theme: {
      themes: {
        light: {
          primary: '#00b29d',
          secondary: '#ef4f5d',
          third:'#8fa1ad',
          menus:'#7b8488',
          accent: '#00bcd4',
          error: '#b71c1c',
          textcolor: '#5e5e5e',
          washed:'#8ed2b3',
          widgetBase:'#112539',
        },
        dark: {
          primary: '#00b29d',
          secondary: '#ef4f5d',
          third:'#8fa1ad',
          accent: '#00bcd4',
          error: '#b71c1c',
          textcolor: '#5e5e5e',
          gold:'#997d56',
          widgetBase:'#112539',
        },
      },
    },




});
