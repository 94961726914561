<template>

  <v-dialog
    v-model="dialog"
    max-width="250"
  >

    <v-card>

      <v-card-title class="headline">
        <v-icon class="pr-2" color="primary">mdi-clock</v-icon>
      Time
      </v-card-title>

      <v-card-text>
        <v-container class="text-h5">
          <v-btn color="primary" icon @click="timeDown">
        <v-icon >mdi-arrow-left-drop-circle-outline</v-icon>
        </v-btn>
      {{tempTime.time}}
      <v-btn color="primary" icon @click="timeUp">
      <v-icon>mdi-arrow-right-drop-circle-outline</v-icon>
      </v-btn>
      </v-container>
      </v-card-text>




  <v-card-actions class="d-flex flex-wrap elevation-5">
      <v-spacer></v-spacer>


      <v-btn
        color="primary"
        text
        @click="openEditDialog"
      >
      <v-icon color="primary" small class="pr-1"
      >mdi-plus</v-icon>
        ADD ITEM
      </v-btn>

      <v-btn
        color="grey"
        text
        @click="dialog = false"
      >
        Close
      </v-btn>
    </v-card-actions>




    </v-card>
  </v-dialog>



</template>




<script>

export default {
  data() {
    return {
      dialog:false,
      time:{"time":"00:00"},
      tempTime:{"time":"00:00"},
      newTime:{"time":"00:00"},
    }},
  name: 'timelineAddNewItem',
  created() {

    },
  components: {


  },
  watch:{
    dialog(){
      // this is to delay animation on closing
      let that = this
      let delay = 0
      if(this.dialog == false) delay = 200
      setTimeout(function(){
        that.tempTime = JSON.parse(JSON.stringify(that.time))
      }, delay);
    }
  },
  props:{

  },
  computed: {


  },
  methods:{
    timeUp(){
    this.tempTime.time = this.addTime(this.tempTime.time,5);
    },
    timeDown(){
    this.tempTime.time = this.addTime(this.tempTime.time,-5);
    },
    openEditDialog(){
      //this.tempTime

      let seats = this.$parent.currentSelectedTableSeats
      this.dialog = false
      this.$parent.editMode = false,
      this.$parent.moveMode = false,
      this.$parent.$refs.timelineItemEditor.isSeated = false

      this.$parent.$refs.timelineItemEditor.data.time = this.tempTime.time
      this.$parent.$refs.timelineItemEditor.data.loca = this.$parent.currentSelectedSectionName
      this.$parent.$refs.timelineItemEditor.data.tableId = this.$parent.currentSelectedTable
      this.$parent.$refs.timelineItemEditor.data.table = this.$parent.currentSelectedTableName
      this.$parent.$refs.timelineItemEditor.guestName = null
      this.$parent.$refs.timelineItemEditor.guestPhone = null



      this.$parent.$refs.timelineItemEditor.data.timestamp = this.timeToTimestamp(this.tempTime.time)

      this.$parent.$refs.timelineItemEditor.isLoading = false
      this.$parent.$refs.timelineItemEditor.isEditingMode = false
      this.$parent.timelineItemEditorDialog = true
      this.$parent.$refs.timelineItemEditor.data.tagList.chips = []
      this.$parent.$refs.timelineItemEditor.data.tagList.items = this.$parent.data.tagNames
      this.$parent.$refs.timelineItemEditor.data.guests = this.$parent.createSelected(seats.seats_min,seats.seats_min,seats.seats_max)


    },
    createSelected(selected,min,max){
      let arr = []
      for (var i = min; i < max + 1; i++) {
        arr.push(i)
      }
      return {'selected':selected,'list':arr}
    },
    timeToTimestamp(time){
      return this.$parent.calcCurrentTimestamp(this.$parent.currentViewTime,time)
    },
    addTime(time, add){
  let hhmm = time.split(':')
  let totalMinutes = +( hhmm[0] * 60 ) + +hhmm[1] + +add
  let newhh = ("0"+Math.floor(totalMinutes / 60)).slice(-2);
  let newmm = ("0"+totalMinutes % 60).slice(-2);
  return newhh + ':' + newmm
    },
  },
}
</script>


<style>
</style>
