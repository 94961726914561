<template>
  <div class="tli">

    <div  @click.stop="openDialog(item.payload,thisline,loca)"
    class="cont hand hover" v-for="(item,index) in thisline.elements" :key="index"
      :class="[item.warning  ? ['ove-overlap']:['ove-default'],item.payload.tags[0].seated ? ['seated'] : ['notseated']]"

    :style="{'width':item.payload.dura*2+'px', 'left':item.overlap*2+'px'}">

      <div class="trunc tit ">
        <div class="guests-ico blue-grey lighten-1">
        {{item.payload.guests}}x
        </div>
        {{item.payload.surname}} {{item.payload.name}}
      </div>

<div class="trunc sml hand">
      <template v-for="(tag,i) in item.payload.tags">



            <span :key="i" outlined class="ma-1 grey--text">
            <v-icon left small color="grey">
              {{tag.icon}}
            </v-icon>
            {{tag.value}}
          </span>



      </template>
    </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Table',
  props:{
    thisline:Object,
    loca:String,

  },
  data() {
    return {
    }
  },
  computed:{

  },

  components:{

  },
  methods:{
    openDialog(payload,line,loca){
      line['loca'] = loca

      this.$emit('openItem',{payload:payload,line:line})

    //  this.$parent.$parent.$refs.timelineItemPreview.dialog = true
    //  this.$parent.$parent.$refs.timelineItemPreview.data = payload
    //  this.$parent.$parent.$refs.timelineItemPreview.line = line
    //  this.$parent.$parent.$refs.timelineItemPreview.data.table = line.table
    //  this.$parent.$parent.$refs.timelineItemPreview.data.loca = this.loca
    },
  },
}
</script>

<style>
.tli .seated{background: #fdccd7!important;}
.tli .notseated{background: #e1ffdc!important;}
.tli .hover:hover{
  background-color: #fbfbfb;

}
.tli .hand{
  cursor: pointer;
}
.tli .v-icon{
  margin-right:0px;
}
.tli .sml{
  font-size:12px
}
.tli .tit{
  font-weight: 500;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left:5px;

}
.tli .guests-ico{
  color: white;
  font-size: 12px;
  font-weight: 900;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: -15px;
  right: 0;
  position: absolute;

}
.tli .trunc{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tli .cont{
  position: relative;
  vertical-align: top;
  background: white;
  padding:5px;
  min-height:89px;
  display: inline-block;
  border-radius: 2px;
}
.tli .ove-default{
  border-left:2px solid #08b39e;
}
.tli .ove-overlap{
  border-left:2px solid #f44335;
}

</style>
