<template>
  <div >

  <v-list-group
          :value="!setup.opened"
          no-action
          sub-group

          prepend-icon="mdi-chevron-down"
          active-class="active-v-list primary--text"
          class="list-folder"
        >
        <template v-slot:activator>
          <v-list-item>
                   <v-list-item-content>
                     <v-list-item-title class="primary--text body-2">{{setup.title}}</v-list-item-title>
                   </v-list-item-content>

                 <v-list-item-action>
                   <div v-if="counter.new > 0" class="text-xs-center "><v-chip small color="primary white--text">{{counter.new}}/{{counter.total}}</v-chip></div>
                    <div v-else class="text-xs-center "><v-chip small color="grey--text">{{counter.total}}</v-chip></div>
                 </v-list-item-action>
                  </v-list-item>
                 </template>
  <v-list>

    <v-list-item
  v-for="(file,index) in list"
  :key="file.id"
  :class="index != Object.keys(list).length - 1 ? 'border-bottom' : ''"
  style="cursor: pointer "
   v-ripple="{ class: `grey--text` }"
   @click="open(file)"
>
<v-list-item-avatar size="25">
  <v-icon
    :class="[file.unread ? 'primary' : 'grey']"
    dark
    small
    v-text="file.unread  ? file.icon_new : 'mdi-check'"
  ></v-icon>
</v-list-item-avatar>

  <v-list-item-content class="mt-2 mb-2">
    <v-list-item-title v-text="file.title" class="list-text-truncate body-2" :class="[file.unread ? 'font-weight-bold' : '']"></v-list-item-title>
    <v-list-item-subtitle class="list-text-truncate body-2" v-text="file.subtitle"></v-list-item-subtitle>
  </v-list-item-content>


</v-list-item>

</v-list>

</v-list-group>

</div>

</template>

<script>
export default {
  name: 'singleList',
  props:{
    setup:Object,
    list:Array,
  },
  data() {
    return {
      selectedItem:null,

    }
  },

  computed:{
    counter(){
      var count = 0;
      var nw = 0
           for(let i=0; i<this.list.length; i++){
            count++
               if (this.list[i].unread==true)
                   nw++;
                 }
      return {'total':count,'new':nw}

    },
  },

  components:{

  },
  methods:{
    open(file){
    this.$emit('callBack', file)
    },
  },
};
</script>
<style scope>
.v-list{padding:0px;}
.list-text-truncate{
  width:230px;
  display:inline;
}
.list-folder{
  background: white;
  border-radius: 5px;
    margin: 7px;
    border: 1px solid #efefef;
}
.active-v-list{
  background: #e1ffdc;
border-bottom: 1px solid #cfefc9;

}
.border-bottom{
  border-bottom:1px solid #e6e6e6;
}
.v-application--is-ltr .v-list-group--sub-group .v-list-item__action:first-child, .v-application--is-ltr .v-list-group--sub-group .v-list-item__avatar:first-child, .v-application--is-ltr .v-list-group--sub-group .v-list-item__icon:first-child {
    margin-right: 15px;
}
.v-application--is-ltr .v-list-group--sub-group .v-list-group__header {
    padding-left: 18px;
}
.v-application--is-ltr .v-list-group--sub-group .v-list-group__items .v-list-item {
    padding-left: 15px;
}
</style>
