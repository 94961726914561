<template>
<div>




<timelineTable ref="timelineTable" ></timelineTable>

</div>
</template>

<script>
import timelineTable from '@/components/timeline/timelineTable';

export default {
  name: 'BookingsTable',
  data() {
    return {

      isDrawerOpen:false
    }
  },
  components:{
    timelineTable,

  },
  watch:{
    refreshKey(){
      this.refresh()
    },
  },
  computed:{
    refreshKey(){
      return this.$store.state.refreshKey
    },
  },
methods:{
  refresh(){
    this.$refs.timelineTable.fetchData(  Math.round(+new Date()/1000) )
  },
},
}
</script>

<style>
.navbarleft{
  width:100px;
  background:grey;
}
.mainbody{
  background:red;
  width:100%;
}


</style>
