<template>



  <v-dialog v-model="loginFormDialog" max-width="250" persistent>
    <v-card>
      <v-progress-linear
      indeterminate
        :active="loading"
      color="primary"
    ></v-progress-linear>
      <v-card-title class="headline">
        <v-icon class="pr-2" color="primary">mdi-lock-outline</v-icon>
        Quick Login
      </v-card-title>
      <v-form ref="form" @submit.prevent="attemptLogin" autocomplete="on">
      <v-card-text>
        <v-fade-transition>
          <div class="red--text text-overline">{{loginFailMessage}}</div>
        </v-fade-transition>



          <validation-provider rules="required|email" v-slot="{ errors }">
          <v-text-field v-model="email" label="Email" type="text" name="email"
           autofocus
          :error-messages="errors">
          </v-text-field>
          </validation-provider>

          <v-text-field v-model="password"

          name="password" label="Password"



          type="password" hint="At least 8 characters"

          ></v-text-field>



      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text type="submit">
          Login
        </v-btn>
      </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>



</template>




<script>
  import axios from 'axios';
  //import router from '@/router'
  import { ValidationProvider, extend } from 'vee-validate';
  import { required, email } from 'vee-validate/dist/rules';

  extend('required', {
    ...required,
  message: 'This field is required'
  });

  extend('email', {
    ...email,
    message: 'Email must be valid',
  })

  export default {
    data() {
        return {
          email: '',
          password: '',
          loading: false,
          loginFailMessage:'',

        }
      },
      name: 'quickLoginForm',

      created() {

      },
      components: {
        ValidationProvider,

      },
      props: {

      },
      computed: {
        loginFormDialog: {
          get() {
              return this.$store.state.quickLoginForm;
            },
            set() {

            }
        },

      },
      methods: {


        attemptLogin() {

            this.loading = true
            let that = this
            let link = this.$store.getters.userAuthServer
            let payload = {
              'email': this.email,
              'password': this.password
            }
            axios.post(link + '/api/login', payload)

            .then(response => {
        
              if (response.status == 200 && response.data.status == 'success') {

                that.$store.dispatch('updateisUserAuthentificated', true)
                that.$store.dispatch('updatetokenAuthServer', {
                  'access_token': response.data.access_token,
                  'token_type': response.data.token_type,
                  'expires_in': response.data.expires_in
                })
                that.loading = false
                that.loginFailMessage = ''
                that.$store.dispatch('updateQuickLoginForm', false)

                axios(that.$store.state.originalRequest).then(
                  that.$store.dispatch('refreshKey')
                )

                //console.log(this.$store.state.previousPage);
            //router.push(this.$store.state.previousPage)
              }

            })
            .catch(function () {
              console.log('Login Failed')
              that.loginFailMessage = 'Login Failed'
              that.loading = false
            })
          },


      },

  }

</script>


<style>
</style>
