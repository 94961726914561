<template>
  <div>
    <v-toolbar color="menus" dark flat>
      <v-tabs v-model="tab" align-with-title slider-size="3" color="white">
        <v-tabs-slider color="primary"></v-tabs-slider>
        <template v-for="title in headers">
          <v-tab :key="title.key">{{title}}</v-tab>
        </template>
      </v-tabs>
    </v-toolbar>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'tabTopNav',
  data(){
    return{
      tab:[]
    }
  },
  props:{
    headers:Array,
  },
  components: {

  },
  watch:{
    tab(val){
      this.$parent.tab = val;
    },
  },
  computed:{

  },
}
</script>
