<template>
<v-dialog scrollable v-model="dialog" :max-width="$vuetify.breakpoint.lg ? 1000 : '135vh'"
:fullscreen="$vuetify.breakpoint.sm"
>

  <v-card>


    <v-card-title class="headline" style="border-bottom:1px solid #ebebeb">
      <v-icon class="pr-2" color="primary">mdi-calendar</v-icon>
      {{dayNameFromTimestamp($parent.$parent.currentViewTime)}},
      {{timestampToDdMmyyyy($parent.$parent.currentViewTime)}}

      <v-spacer></v-spacer>
      <v-btn icon @click="dialog=false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

<v-card-text class="pa-0" >

    <v-row no-gutters style="min-height:100vh">

      <v-col cols="3" xs="4" class="pa-2" style="background:#f7f7f7;border-right:1px solid #ebebeb">
        <div style="position: -webkit-sticky; position: sticky; top: 0px">
        <diaryButtonMenu
        class="ml-3 mr-3 mb-3 mt-3"
        title="Add New"
        @clicked="addNewClick"
        :items="formList"
        />
        <diaryListMenu
        @clicked="clickedListMenu"
        :items="formListWithCalcItems"
        @setFilter="changeCurrentFilter"
        :selectedItem="currentFilterFormId"
        />
      </div>
      </v-col>

      <v-col cols="9" xs="8" class="pa-3 rounded" style="background:#f2f2f2;">





            <diaryList :list="diaryList" @clicked="clickedList"></diaryList>

          <v-dialog v-model="viewDialog" scrollable :max-width="$vuetify.breakpoint.lg ? 900 : '130vh'">
            <v-card>
              <v-card-title class="headline" style="border-bottom:1px solid #ebebeb">

                <v-icon class="pr-2" color="primary">{{  currentItem.icon }}</v-icon>

                {{ currentItem.type }}

                <v-spacer></v-spacer>
                <v-btn icon @click="viewDialog=false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="pa-8" style="background:#f2f2f2;">

          <diaryItemView ref="diaryItemView" @reload="reloadTimeline" @changeView="changeView" :currentViewTime="currentViewTime" :currentItem="currentItem" :currentForm="currentForm"></diaryItemView>
        </v-card-text>
        <v-card-actions class="pa-4" style="border-top:1px solid #ebebeb">
          <v-btn
          v-if="this.currentItem"
            color="red"
            text
            @click="deleteItem"
            >
            Delete
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="viewDialog=false"
            >
            Close
          </v-btn>
          <v-btn
            color="primary"
            @click="save()"
            >
            Save
          </v-btn>
        </v-card-actions>
          </v-card>
          </v-dialog>

      </v-col>

    </v-row>
</v-card-text>

  </v-card>

  <v-snackbar
        v-model="snackbar.state"
        timeout="1000"
      >
      {{ snackbar.text }}
    </v-snackbar>
</v-dialog>
</template>




<script>
import diaryList from '@/components/diaryV3List';
import diaryButtonMenu from '@/components/diaryV3ButtonMenu';
import diaryListMenu from '@/components/diaryV3ListMenu';
import diaryItemView from '@/components/diaryV3ItemView';



export default {
  name: 'diaryV3Dialog',

  components:{
  diaryList,
  diaryListMenu,
  diaryButtonMenu,
  diaryItemView
  },
  props:['currentViewTime'],
  data () {
    return {
      viewDialog:false,
      currentFilterFormId:0,
      currentForm:0,
      currentItem:{},
      dialog:false,
      snackbar: {state:false,
                text:"",
                color:""}

    }
  },
watch:{
  dialog(curr){
    if(curr== false ){

      //reset values when dialog opened
      this.currentFilterFormId = 0
      this.currentForm = 0
    }
  }
},
computed:{

formListWithCalcItems(){
  const forms = this.$store.state.formData.forms
  let filtered = [
    { "id": 0, "form_name": "Display All", "ico": "mdi-format-list-checkbox", "count": 0 }
  ]
  if(forms){
    for (const item of forms) {
      let count = this.filterItemsListByFormId(this.$store.state.diaryData,item.id)
      item.count = count.length
      filtered.push(item)
    }
  }

  return filtered
},


diaryList(){
      const itemsList = this.filterItemsListByFormId(this.$store.state.diaryData, this.currentFilterFormId)
      const groups = {};
  for (const item of itemsList) {
    const formId = item.formId;
    if (!groups[formId]) {
      groups[formId] = [];
    }
    groups[formId].push(item);
  }

  // Create a one-dimensional array to store the groups.
  const groupsArray = [];
  for (const formId in groups) {
    groupsArray.push(...groups[formId]);
  }

    return groupsArray;
  //return this.$store.state.diaryData
  //return this.filterItemsListByFormId(this.$store.state.diaryData, this.currentFilterFormId)
},

 formList(){
   return this.$store.state.formData.forms
 },

},

  methods:{

    changeCurrentFilter(){
    //this.currentFilterFormId = val
    },

    timestampToDdMmyyyy(timestamp) {
      const date = new Date(timestamp * 1000);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      // Pad the day and month with a leading zero if necessary.
      const dayString = day.toString().padStart(2, "0");
      const monthString = month.toString().padStart(2, "0");

      // Return the date in dd/mm/yyyy format.
      return `${dayString}/${monthString}/${year}`;
    },

     dayNameFromTimestamp(timestamp) {
      const date = new Date(timestamp * 1000);
      const day = date.getDay();

      // Convert the day number to a day name.
      const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const dayName = dayNames[day];

      return dayName;
      },

    deleteItem(){

      this.$refs.diaryItemView.removeTaskAxios({itemId:this.currentItem.id});
      this.viewDialog=false
      this.reloadTimeline()
    },

    save(){
      this.$refs.diaryItemView.save();
      this.viewDialog = false
      this.snackbar.text = 'All changes saved!'
      this.snackbar.state = true
    },

    reloadTimeline(){
      let currentViewTime = this.$parent.$parent.currentViewTime
      this.$parent.$parent.fetchData(currentViewTime)
    },

    clickedListMenu(item){
      this.currentFilterFormId = item.id
    },
    clickedList(item){
    //  console.log(item);

      this.resetForm()
      this.createCurrentForm(item)

      this.viewDialog = true

      this.currentForm = item.formId
      this.currentItem = item

    },
    addNewClick(item){
      this.currentItem = {type: item.form_name, icon: item.ico}
      this.currentForm = item.id
      this.resetForm()
      this.viewDialog = true


    },
    changeView(item){
    //  console.log(item);
      this.currentPanel = item
    },
    resetForm(){

        this.$store.dispatch('updateCurrentFormData',this.$store.state.formDataMaster.templates[this.currentForm])
    },

     filterItemsListByFormId(itemsList, formId) {
       if(formId == 0) return itemsList
       return itemsList.filter((item) => item.formId === formId)
      },

     addResultsToTemplate(currentTemplate, results) {
      // Create a new template object.

      const updatedTemplate = Object.assign([], currentTemplate)



      // Iterate over the results and add them to the updated template.
      for (const result of results) {
        const id = result.id;
        const value = result.value;

        // Find the item in the updated template with the matching id.
        const item = updatedTemplate.find(group => group.items.find(item => item.id === id));

        // If the item is found, update its value.
        if (item) {
          item.items.find(item => item.id === id).value = value;
        }
      }

      // Return the updated template.
      return updatedTemplate;
    },

    createCurrentForm(selectedItem){
      const currentTemplate = this.$store.getters.formData

      const currentDiaryItemData = this.$store.state.diaryData.filter(x => x.id === selectedItem.id)

      const currentTemplateArray = Object.values(currentTemplate.templates[selectedItem.formId]);

      const dbData = currentDiaryItemData[0].fields

      const newForm = this.addResultsToTemplate(currentTemplateArray, dbData)

      this.$store.dispatch('updateCurrentFormData',newForm)


    },

  },

}


</script>

<style>

</style>
