<template>


    <v-dialog v-model="tableDialog" max-width="350" >
      <v-card>
        <v-progress-linear
        indeterminate
          :active="loading"
        color="primary"
      ></v-progress-linear>
        <v-card-title class="headline">
          <v-icon class="pr-2" color="primary">mdi-silverware-fork-knife</v-icon>
          Table
        </v-card-title>

        <v-card-text>

          <v-row>
            <v-col cols="12">

           <v-select
          v-model="tableData.location_id"
          :items="locItems"
          item-text="locationName"
          item-value="locationId"
          label="Select"

          single-line
        ></v-select>
         </v-col>
        </v-row>

          <v-row>
            <v-col cols="12">
          <v-text-field
            v-model="tableData.table_no"
           label="Table Number"
           ></v-text-field>
         </v-col>
        </v-row>
           <v-row>
             <v-col cols="6">
               <v-text-field
                 v-model="tableData.seats_min"
                label="Seats Min."
                ></v-text-field>
             </v-col>
             <v-col cols="6">
               <v-text-field
                 v-model="tableData.seats_max"
                label="Seats Max."
                ></v-text-field>
             </v-col>
           </v-row>

<v-row>
         <v-col>
           <h3 class="mb-3">TABLE FEATURES</h3>
           <v-divider class="mb-5"/>
           <v-checkbox
           class="mt-n2"
          v-for="(option) in features"
          multiple
          v-model="tableFeaturesSelected"
          :key="option.key"
          :value="option.id"
          :label="option.description"
       ></v-checkbox>
           </v-col>
         </v-row>


        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text type="submit" @click="del">
            Del
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text type="submit" @click="save">
            Save
          </v-btn>
          <v-btn color="grey" text type="submit" @click="close">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

</template>




<script>

export default {
  data() {
    return {
      loading:false,
      tableDialog:false,
      features:[],
      tableFeaturesSelectedFormated:[],
      tableFeaturesSelected:[],

        tableData:{},
        locItems:{},



    }},
  name: 'tableLayoutTable',
  created() {

    },
    watch: {
    tableFeaturesSelected: function (val) {
      var newVal = {}
      for (var i = 0; i < val.length; i++) {
        newVal['f'+val[i]] = 1
      }
      this.tableFeaturesSelectedFormated = JSON.stringify(newVal)
    },
  },
  components: {


  },
  props:{

  },
  computed: {

  },
  methods:{
    del(){
    console.log('del');
    },
    close(){
      this.tableDialog = false
    },

    save(){
      this.tableData.options = this.tableFeaturesSelectedFormated
      this.$parent.updateDate(this.tableData)
     this.tableDialog = false
    },




  },
}
</script>


<style>
</style>
