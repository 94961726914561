import axios from 'axios';
import store from '../store'

//import router from '@/router'




export default function setup() {
    axios.interceptors.request.use(function(config) {
        const token = store.getters.tokenAuthServer.access_token;
        //let token = this.$store.getters.tokenAuthServer.access_token
        //let headers = { token: `${token}` };
      config.params = config.params || {};
      config.params['estaActive'] = store.getters.estaActive;


        if(token) {
            config.headers['Authorization'] = `Bearer ${token}`;
            //config.headers['X-EST-ACTIVE'] = store.getters.estaActive
        }
        return config;
    }, function(err) {
        return Promise.reject(err);
    });


axios.interceptors.response.use( (response) => {

if(response.headers.authorization){
let newtoken = response.headers.authorization
newtoken = newtoken.replace("Bearer ", "");

store.dispatch('updatetokenAuthServer', {
  'access_token': newtoken,
})
}

return response;

},
async function (error) {

  //const originalRequest = error.config;
  if(error.response){
  if (error.response.status === 401 ) {

    store.dispatch('updatetokenAuthServer', {
      'access_token': '',
    })
    store.dispatch('updateisUserAuthentificated', false)

    store.dispatch('updateQuickLoginForm', true)
    //store.dispatch('updateOriginalRequest', originalRequest)
    //console.log(store.getters.isUserAuthentificated);

    //originalRequest._retry = true;

    //router.push({'name':':Login'})
  }
  return Promise.reject(error);
}}
)

}
