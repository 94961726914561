<template>
  <div>

  </div>
</template>




<script>


export default {
  name: 'CustomThemes',
  data() {
    return {


themes:{


  'teal':{
      light: {
        primary: '#00b29d',
        secondary: '#4caf50',
        third:'#8fa1ad',
        menus:'#7b8488',
        accent: '#00bcd4',
        error: '#b71c1c',
        textcolor: '#5e5e5e',
        washed:'#42b983',
        widgetBase:'#112539',
      },
      dark: {
        primary: '#00b29d',
        secondary: '#03a9f4',
        third:'#8fa1ad',
        accent: '#00bcd4',
        error: '#b71c1c',
        textcolor: '#5e5e5e',
        gold:'#997d56',
        widgetBase:'#112539',
      },
  },


  'blue':{
      light: {
        primary: '#2196f3',
        secondary: '#03a9f4',
        third:'#8fa1ad',
        menus:'#7b8488',
        accent: '#03a9f4',
        error: '#b71c1c',
        textcolor: '#5e5e5e',
        washed:'#64baff',
        widgetBase:'#112539',
      },
      dark: {
        primary: '#00b29d',
        secondary: '#ef4f5d',
        third:'#8fa1ad',
        accent: '#00bcd4',
        error: '#b71c1c',
        textcolor: '#5e5e5e',
        gold:'#997d56',
        widgetBase:'#112539',
      },
  },


}






    }
  },
  created(){

  },
  components:{



  },
}

</script>
