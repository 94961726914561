<template>
<div>

<loginForm></loginForm>


</div>
</template>

<script>

import loginForm from '@/components/modals/loginForm';



export default {
  name: 'BookingsTable',
  data() {
    return {}
  },
  components:{
    loginForm,


  },
  created(){

  },
  methods:{
    updateAvailable(event) {
      console.log(event)
    },
    version(){

      console.log('version');
    },



  },
}
</script>

<style>
.navbarleft{
  width:100px;
  background:grey;
}
.mainbody{
  background:red;
  width:100%;
}


</style>
