<template>

  <v-dialog persistent v-model="dialog" scrollable max-width="570" :fullscreen="fullscreen"

  >
    <v-card v-cloak>
      <v-progress-linear indeterminate :active="isLoading" color="primary"></v-progress-linear>


      <template v-if="!searchActive">
      <v-card-title class="headline" v-if="isLoading">
        <v-icon class="pr-2" color="primary">mdi-timer-sand</v-icon>
        Loading...
      </v-card-title>

      <v-card-title class="headline" v-else>
        <v-icon class="pr-2" color="primary">mdi-account-circle-outline</v-icon>
        <span v-if="isEditingMode">{{data.surname}} {{data.name}}</span>
        <span v-else>New Item</span>

      </v-card-title>
    </template>



      <v-slide-x-transition>
        <v-card-text class="text-left" v-if="!isLoading">
          <v-container class="pa-0" v-if="isEditingMode">
            <v-row no-gutters>


              <v-col cols="12" sm="5">

                <v-list-item class="pl-0">
                  <v-list-item-avatar class="ma-0">
                    <v-icon color="primary">
                      mdi-phone-in-talk
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{data.phone_number}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="7">
                <v-list-item class="pl-0">
                  <v-list-item-avatar class="ma-0">
                    <v-icon color="primary">
                      mdi-at
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{data.email}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-container>



<template v-if="!searchActive">

          <v-divider></v-divider>





          <v-container class="d-flex align-start flex-row flex-wrap justify-space-between">
            <v-sheet>
              <v-col class="text-center">
                <v-icon color="primary">mdi-alpha-t-box-outline</v-icon><br>
                <div style="max-width:100px;" class="text-capitalize">{{convertToLowerCase(data.loca)}}</div>
                <div class="mt-n1 text-h6 black--text">#{{data.table}}</div>
              </v-col>
            </v-sheet>
          <v-sheet>
            <v-col class="text-center">
              <v-icon color="primary">mdi-account-multiple-outline</v-icon><br>
              <span>Guests</span>
              <div class="mt-n1 text-h6 black--text">{{data.guests.selected}}</div>
            </v-col>
          </v-sheet>
          <v-sheet>
            <v-col class="text-center">
              <v-icon color="primary">mdi-clock-outline</v-icon><br>
              <span>Time</span>
              <div class="mt-n1 text-h6 black--text">{{data.time}}</div>
            </v-col>
          </v-sheet>
          <v-sheet>
            <v-col class="text-center">
              <v-icon color="primary">mdi-clock-outline</v-icon><br>
              <span>Duration</span>
              <div class="mt-n1 text-h6 black--text">{{data.duration.selected}} "</div>
            </v-col>
          </v-sheet>
          </v-container>

          <div class="text-center" v-if="systemTags.creator_name">
          <v-icon small color="primary">mdi-account-arrow-right-outline</v-icon> Created by: {{systemTags.creator_name}} {{created_at}}
          </div>
          <v-divider ></v-divider>


              <v-row class="mt-2 mb-n10">
                <v-col cols="5">
                  <v-text-field v-model="servers_innitials"
                  size="6"
                  outlined
                  hint="Your name or initials"
                  label="Staff Name / Initials"></v-text-field>
                </v-col>
                <v-col>
                </v-col>

                <v-col cols="3">
                  <v-select outlined v-model="data.guests.selected" :items="data.guests.list" label="Guests">
                  </v-select>
                </v-col>
                <v-col cols="3">
                  <v-select outlined v-model="data.duration.selected" :items="data.duration.list" label="Duration">
                  </v-select>
                </v-col>
              </v-row>

</template>

          <v-row>
            <v-col cols="12">

              <template v-if="!isEditingMode">

                <template v-if="searchForGuest">
                  <userSearchBar ref="userSearchBar"></userSearchBar>
                  <v-btn text small color="primary" @click="searchForGuest = !searchForGuest">

                  </v-btn>
                </template>

                <template v-if="!searchForGuest">
                  <div class="text-field-group">
                  <v-text-field v-model="guestName" label="First Name" required></v-text-field>
                  <v-text-field v-model="guestName" label="Surname" required></v-text-field>
                  <v-text-field v-model="guestPhone" label="Phone Number" required></v-text-field>
                </div>
                  <v-btn text small color="primary" @click="searchForGuest = !searchForGuest">
                    Search For Guest
                  </v-btn>
                </template>

              </template>




            </v-col>
          </v-row>
<template v-if="!searchActive">
          <v-row no-gutters v-if="1==1">
            <v-col cols="12">
              <v-select v-model="data.tagList.chips" :items="tagListItems"
              hint="Comon tags such as Vegan, Celiac..."
              outlined
               label="Select Tags (optional)" multiple>
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip class="ma-1 text-body-2" v-bind="attrs" :input-value="selected" outlined small color="blue-grey darken-1" close @click="select" @click:close="remove(item)">
                    <strong>{{ item }}</strong>
                  </v-chip>
                </template>
              </v-select>
            </v-col>
          </v-row>



</template>





<v-text-field v-model="short_note" label="Short Note (optional)"></v-text-field>

<template v-if="!isEditingMode">
    <v-switch v-model="isSeated"
      inset

  color="primary"
  label="Customer Seated / Arrived"
  ></v-switch>
</template>



        </v-card-text>
      </v-slide-x-transition>



      <v-card-actions class="d-flex flex-wrap elevation-6 ">


        <div class="sq-btn ">
        <v-btn color="red" text depressed v-if="!isLoading && isEditingMode" @click="removeThisItem('delete')">
          <v-icon small>mdi-trash-can-outline</v-icon>
          <div>Remove</div>
        </v-btn>
        </div>

        <div class="sq-btn ">
          <v-btn color="orange" text depressed v-if="!isLoading && isEditingMode" @click="removeThisItem('cancel')">
          <v-icon small>mdi-account-off-outline</v-icon>
          <div>Cancel</div>
        </v-btn>
      </div>

      <div class="sq-btn ">
        <v-btn color="orange" text depressed v-if="!isLoading && isEditingMode" @click="removeThisItem('noshow')">
        <v-icon small>mdi-eye-off</v-icon>
        <div>No show</div>
      </v-btn>
    </div>

        <v-spacer></v-spacer>


        <v-btn color="primary" text v-if="!isLoading && !isEditingMode" @click="submitItem('create')">
          Save
        </v-btn>

        <v-btn color="primary" text v-if="!isLoading && isEditingMode" @click="submitItem('update')">
          Update
        </v-btn>

        <v-btn color="grey" text @click="dialog = false; isLoading = true;">
          Close
        </v-btn>
      </v-card-actions>




    </v-card>
  </v-dialog>



</template>




<script>

import userSearchBar from '@/components/timeline/userSearchBar';

  export default {
    data() {
        return {
          //  dialog:false,
          servers_innitials:null,
          fullscreen:false,
          searchActive:false,
          guestName:null,
          guestPhone:null,
          searchForGuest:true,
          short_note:null,
          isLoading:false,
          isEditingMode:false,
          isSeated:false,
          data: {
            //"available_tables":{'selected':2,'list':[1,2,3,4]},
            "timestamp": 123456,
            "name": "empty",
            "phone": "12345",
            "time":'10:30',
            "loca":'Bar Area',
            "table":5,
            "tableId":5,
            "guests":{'selected':2,'list':[1,2,3,4]},
            "duration":{'selected':120,'list':[60,90,120]},
            "tagList":{
              "chips": [],
              "items": [],
            }
          },


        }
      },
      name: 'timelineItemEditor',
      mounted() {

      },
      components: {
        userSearchBar

      },
      props: {

      },

      computed: {
        systemTags(){
          if(this.data.tags){
          let tags = JSON.parse(this.data.tags)
          return tags.system[0]
        }
        return ''
        },
        created_at(){
          let timestamp = this.data.created_at
          var date = new Date();
          date.setTime(timestamp*1000);
          let dateString = date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
          return dateString
        },
        tagListItems(){
          let items = this.data.tagList.items

          items = items.concat(this.data.tagList.chips)
          return items
        },

        dialog: {
          get() {
              return this.$parent.timelineItemEditorDialog
            },
            set(val) {
              this.$parent.timelineItemEditorDialog = val
              this.short_note = null
            }
        },

      },
      methods: {

        convertToLowerCase(val){
          //return val.charAt(0) + val.substring(1).toLowerCase();
          //return val.toLowerCase();
          if(val) return val.toLowerCase();
          return val
        },
        updateDataFromSearch(){

          this.searchForGuest = false
        },
        isTagsOpenToggle(){
          this.isTagsOpen = !this.isTagsOpen
        },
        remove (item) {

      this.data.tagList.chips.splice(this.data.tagList.chips.indexOf(item), 1)
        this.data.tagList.chips = [...this.data.tagList.chips]
              },


          submitItem(type){
            let tags = this.data.tagList.chips
            if(this.servers_innitials != null){tags.push("[Staff:" + this.servers_innitials + "]")}
            let sys_tags = [{"seated":this.isSeated}]
            if(this.short_note){
            tags.push(this.short_note+' ('+ this.$store.state.userActive.initials +')')
          }

          this.servers_innitials = null

            let guestId = null

            if(type == "create"){

            if(guestId == null){
              this.guestName = this.$refs.userSearchBar.resultSearch.name
              this.guestSurname = this.$refs.userSearchBar.resultSearch.surname
              this.guestPhone = this.$refs.userSearchBar.resultSearch.phone_number
            }
            if(this.$refs.userSearchBar.resultSearch.id > 0) guestId = this.$refs.userSearchBar.resultSearch.id
          }



            let payload = {
              'itemId':this.data.id,
              'viewTime': this.$parent.currentViewTime,
              'guestId':guestId,
              'guestName': this.guestName,
              'guestSurname': this.guestSurname,
              'guestPhone':this.guestPhone,
              'timestamp':this.data.timestamp,
              'tableId':this.data.tableId,
              'duration':this.data.duration.selected,
              'numberOfGuests':this.data.guests.selected,
              'tags':tags,
              'sys_tags':sys_tags,
            }

            let payloadUpdate={
              'itemId':this.data.id,
              'viewTime': this.$parent.currentViewTime,
              'duration':this.data.duration.selected,
              'numberOfGuests':this.data.guests.selected,
              'tags':tags,
              'sys_tags':sys_tags,
            }

            if(type == 'create')this.$parent.createNewItemAxios(payload)
            if(type == 'update')this.$parent.updateItemAxios(payloadUpdate)

          },



        removeThisItem(type){

          if(type == 'delete') {
            this.$parent.$refs.timelineDeleteItem.isNoShow = false
            this.$parent.$refs.timelineDeleteItem.isCancel = false
          }
          if(type == 'noshow') {
            this.$parent.$refs.timelineDeleteItem.isCancel = false
            this.$parent.$refs.timelineDeleteItem.isNoShow = true}
          if(type == 'cancel') {
            this.$parent.$refs.timelineDeleteItem.isNoShow = false
            this.$parent.$refs.timelineDeleteItem.isCancel = true
          }
          this.$parent.$refs.timelineDeleteItem.dialog = true
          this.$parent.$refs.timelineDeleteItem.bookingId = this.data.id
          this.$parent.$refs.timelineDeleteItem.start_at = this.data.start_at

        },




      },
  }
</script>



<style>
.sq-btn .v-btn:not(.v-btn--round).v-size--default {
  height:auto;}
.sq-btn .v-btn .v-btn__content {
padding:2px;
    display:flex;
    flex-direction:column;
}

.text-field-group{
  border: 1px solid #949494;
border-radius: 5px;
padding: 10px;
}

</style>
