<template>
    <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="date"
          persistent
          width="290px"
        >

          <template v-slot:activator="{ on, attrs }">


            <v-btn
            class="ma-2 white-button" small outlined color="primary"
              v-bind="attrs"
              v-on="on"
            >
            <v-icon dark>
              mdi-calendar-month-outline
            </v-icon>
             <span class="pl-1">{{computedDateFormatted}}</span>
            </v-btn>
          </template>
          <v-date-picker
            v-model="date"
            scrollable
            first-day-of-week="1"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="modal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog.save(date);confirm()"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

</template>




<script>
  export default {

    data: vm => ({

      date: new Date().toISOString().substr(0, 10),
      dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
      modal :false,
      confirmed: false,

    }),

    computed: {
      computedDateFormatted () {
        return this.formatDate(this.date)
      },
    },

    watch: {
      date () {
        this.dateFormatted = this.formatDate(this.date)
      },
      modal(){
        if(this.modal == true) this.confirmed = false
      },
    },

    methods: {
      confirm(){
        this.confirmed = true
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
    },
  }
</script>


<style>
</style>
