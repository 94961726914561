<template>

  <div class="tl-top-menu">

<!--
    <v-btn :class="[isDrawerOpen ? 'ma-2 primary white--text' : 'ma-2 white-button primary--text']" small outlined
     @click="openList">
      <v-avatar
      v-if="false"
        size="30"
        class="red white--text ml-n5 mr-2"
        >
        0
      </v-avatar>
      Folders
    </v-btn>
  -->

  <v-btn class="ma-2 white-button" small outlined color="primary" @click="openNewDiary">
    <v-avatar
    v-if="diaryNumber > 0"
      size="30"
      class="red white--text ml-n5 mr-2"
    >
      {{diaryNumber}}
    </v-avatar>
    Diary
  </v-btn>





  <basicCalendar ref="timelinePickDate"></basicCalendar>

    <v-btn class="ma-2 white-button" small outlined color="primary" @click="changeDateToday">
      Today
    </v-btn>

    <v-btn class="ma-2 white-button" small outlined color="primary" @click="changeDateTomo">
      Tomorrow
    </v-btn>

    <v-btn class="ma-2 white-button" small outlined color="primary"  @click="editMode = !editMode">
      <v-icon v-if="!editMode" dark>
        mdi-plus
      </v-icon>
      <v-icon v-if="editMode" dark>
        mdi-cancel
      </v-icon>
    </v-btn>

    <v-navigation-drawer
        v-model="isDrawerOpen"
        app
        stateless
        left
        color="grey lighten-3"
        disable-resize-watcher
        width="335"
        style="z-index:0!important;margin-left:54px;"
      ><leftList/>
    </v-navigation-drawer>

  <displayDiary ref="refDisplayDiary"/>
  <displayDiaryV3 ref="refDisplayDiaryV3" :currentViewTime="currentViewTime"/>
  </div>


</template>
<script>
import basicCalendar from '@/components/modals/basicCalendar';
import displayDiary from '@/components/modals/displayDiary';
import displayDiaryV3 from '@/components/diaryV3Dialog';
import leftList from '@/components/timeline/leftLists';

  export default {
    name:"timelineTopMenu",
    data () {
      return {
        isDrawerOpen:false,
        mainNavbar:true,
        diaryNumber:0,

      }
    },
    props:['currentViewTime'],
    components:{
      basicCalendar,
      displayDiary,
      displayDiaryV3,
      leftList
    },
    mounted() {
      this.$watch(
        "$refs.timelinePickDate.confirmed",
        (oldVal,newVal) => {
          let selDate = this.$refs.timelinePickDate.date
            selDate = Math.round(Date.parse(selDate) / 1000)
           if(!newVal) this.exeChangeDate(selDate)
        }
      );
    },

    computed:{


      editMode:{
        get(){
          return this.$parent.editMode
        },
        set(val){
          this.$parent.editMode = val
          this.$parent.moveMode = false
        }
      }


    },
    methods:{


      openNewDiary(){
        this.$refs.refDisplayDiaryV3.dialog = true
      },
      openDiary(){
        this.$refs.refDisplayDiary.dialog = true
      },
      openList(){
        this.isDrawerOpen = !this.isDrawerOpen
      },

      exeChangeDate(time){
          this.$parent.fetchData(time)
      },
      changeDateToday(){
        const interval = 1000 * 60 * 60 * 24;
        let startOfDay = Math.floor(Date.now() / interval) * interval
        let time =  startOfDay/1000
        this.exeChangeDate(time)
        this.changeChildDatePicker(time)

      },
      changeDateTomo(){
        let time =  Math.round(+new Date()/1000) + 86400
        this.exeChangeDate(time)
          this.changeChildDatePicker(time)
      },
      changeChildDatePicker(timestamp){
        var formatDate = new Date(timestamp * 1000).toISOString().substr(0, 10);
        this.$refs.timelinePickDate.date = formatDate
      },
    },
  }
</script>
<style>
.z-index-0{z-index:0;}
.tl-top-menu{    height: 50px;
    padding-top: 3px;}
.tl-top-menu{
  background: #e6e6e6;
      margin-left: 1px;
}
.tl-top-menu .white-button{
  background: white;
}
</style>
