<template>
<div>
  <v-list style="background-color: transparent;" >
    <v-list-item-group v-model="selectedItemC" color="primary">

      <v-list-item v-for="(item, i) in items" :key="i" @click="clicked(item)">

        <v-list-item-icon>
          <v-icon size="17" class="text-text-primary mr-n5 ml-3">{{item.ico}}</v-icon>
        </v-list-item-icon>

        <v-list-item-content class="mr-n3 text-body-2 font-weight-medium text-truncate">
          {{ item.form_name }}
        </v-list-item-content>
        <v-list-item-action>
          <span class="text-body-2 font-weight-medium" v-if="item.count > 0">
            {{ item.count }}
          </span>
        </v-list-item-action>
      </v-list-item>

    </v-list-item-group>
  </v-list>
</div>
</template>




<script>

  export default {
    data () {
      return {



      }
    },
     props: ['items','selectedItem'],
    emits: ['clicked','setFilter'],

    methods:{
      clicked(item){
        this.$emit('clicked', item)
      },

    },
    computed:{
      selectedItemC:{
        get(){return this.selectedItem},
        set(val){ this.$emit('setFilter', val) }

      }
    },


  }

</script>
