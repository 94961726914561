<template>
  <div class="text-center">

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
        class="mt-2 mb-2"
        elevation="0"
        width="100%"
        color="primary"
          v-bind="attrs"
          v-on="on"
        >
          {{ title }}

        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="clicked(item)"
        >
          <v-list-item-title>
            <v-icon v-if="item.ico" size="16" class="pr-2 mb-1">{{ item.ico }}</v-icon>
            {{ item.form_name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  export default {
    props: ['title','items'],
    emits: ['clicked'],

    data: () => ({
      // items: [
      //   { id:1, title: 'Note' },
      //   { id:7, title: 'Task' },
      //   { id:7, title: 'Funeral' },
      // ],
    }),
    methods:{
      clicked(item){
        this.$emit('clicked', item)
      }
    },
  }
</script>
