<template>
<div>

<v-row>

<v-col cols="12">

<diaryForm :formsData="formsData"></diaryForm>

</v-col>

<v-col cols="12" align-self="end">




</v-col>
</v-row>




</div>
</template>

<script>
import diaryForm from '@/components/diaryV3Form';
import axios from 'axios';

export default {

  components:{
    diaryForm
  },
  props: ['currentForm','currentViewTime','currentItem'],
  emits: ['changeView','reload'],

  data () {
    return {


    }
  },
  computed: {
     formsData() {
      //  if(this.currentForm)  return this.$store.state.formData.templates[this.currentForm];
       return this.$store.state.currentFormData
     }
   },
  methods:{

    back(){
      this.$emit('changeView', 0)
    },
    save(){
      this.sendNewtaskAxios(this.prepareDataForExport())

      this.back()
    },

    removeTaskAxios(payload){


      let apiUrl = this.$store.state.apiUrl
      axios.post(apiUrl+`/diarytask`,payload)
          .then(response => {
            if(response.status == 200 && response.data.status == 'success'){
                //deleted
                this.$emit('reload')
         }else{
           console.warn('Status: '+response.status+' Response: '+ response.data.status );
         }
          })

    },

    sendNewtaskAxios(payload){
      this.isLoading = true
      let apiUrl = this.$store.state.apiUrl

      axios.put(apiUrl+`/diarytask`,payload)
          .then(response => {
            if(response.status == 200 && response.data.status == 'success'){
              //console.log(response.data);
              this.$emit('reload')
         }else{
           console.warn('Status: '+response.status+' Response: '+ response.data );
         }
          })

    },

    createDescription(fieldList){
      let simpleString = " ";

      for (const groupId in fieldList) {
        const group = fieldList[groupId];
        //console.log(group);
        simpleString += group.title + ':' + group.value +',';
        // for (const item of group.items) {
        //   console.log(item);
        // //  simpleString += item.title + ':' + item.value +',';
        // }
      }
      return simpleString;
    },

    prepareDataForExport(){

    let currentID = null
    if(this.currentItem !== null) currentID = this.currentItem.id

    //const fields = this.extractItemsFromData(this.$store.state.currentFormData)
    const desc = this.createDescription(this.extractItemsFromData(this.$store.state.currentFormData))

      const payload = {
        type:"funeral",
        icon: "mdi-heart",
        fields:this.extractItemsFromData(this.$store.state.currentFormData),
        formID:this.currentForm
      }
      const request = {
        set_by_name : this.$store.getters.userActive.name,
        time : this.currentViewTime,
        display_time: 'Anytime',
        payload :payload,
        description: desc.substring(0,250),
        done: false,
        id:currentID
      }
      return request
    },

    extractItemsFromData(data) {
  // Create an empty array to store the extracted items.
  const items = [];

  // Iterate over the data object and extract all items.
  for (const group of Object.values(data)) {
    for (const item of group.items) {
      items.push(item);
    }
  }

  // Return the extracted items.
  return items;
}

  },
}
</script>
