<template>
  <div>
  <v-simple-table>
     <template v-slot:default>
       <thead>
         <tr>
           <th class="text-left">
             Valid From - To
           </th>
           <th class="text-left">
             Day
           </th>
           <th class="text-left">
             Open Time
           </th>
           <th class="text-left">
             Close Time
           </th>
           <th class="text-left">
             Online
           </th>
           <th class="text-center">
             In-house
           </th>
           <th class="text-center">
             Action
           </th>
         </tr>
       </thead>
       <tbody>
         <tr
           v-for="item in items"
           :key="item.key"
           @click="openDialog(item)"
         >
          <td class="text-left">{{ item.valid.from }} - {{ item.valid.to }}</td>
           <td class="text-left">{{ item.name }}</td>
           <td class="text-left">{{ item.openTime }}</td>
           <td class="text-left">{{ item.closeTime }}</td>
           <td class="text-left"><v-icon color="primary" v-if="item.checkOnline">mdi-check-circle-outline</v-icon></td>
           <td class="text-center"><v-icon color="primary" v-if="item.checkInHouse">mdi-check-circle-outline</v-icon></td>
           <td class="text-left caption primary--text">{{ item.action }}</td>
        </tr>
       </tbody>
     </template>
   </v-simple-table>

   <availabilityEdit ref="availabilityEdit"></availabilityEdit>
 </div>
</template>

<script>
// @ is an alias to /src
//import axios from 'axios';
import availabilityEdit from '@/components/availability/modals/availabilityEdit';



export default {
  name: 'availabilitySchedule',
  components:{
    availabilityEdit
  },
  data(){
    return{
      items: [
                {
                  name: 'Mon, Tue, Wed, Thu, Fri, Sat, Sun',
                  valid:{"from":"01/01/1970","to":"21/10/2030"},
                  daysSelected:[0,1,2,3,4,5,6],
                  openTime: '10:00',
                  closeTime: '23:00',
                  checkOnline:false,
                  checkInHouse:true,
                  action:"",
                },
                {
                  name: 'Mon, Tue, Wed, Thu, Fri, Sat, Sun',
                  valid:{"from":"01/10/2021","to":"30/11/2021"},
                  daysSelected:[0,1,2,3,4,5,6],
                  openTime: '12:00',
                  closeTime: '20:30',
                  checkOnline:true,
                  checkInHouse:false,
                  tablesSelected:[ 5, 8, 11, 12, 14, 16, 20, 21, 22, 0, 1, 2, 3, 4 ],
                  action:"Accept Booking",
                },
                {
                  name: 'Mon, Tue, Wed, Thu, Fri, Sat',
                  valid:{"from":"01/10/2021","to":"30/11/2021"},
                  daysSelected:[0,1,2,3,4,5],
                  openTime: '12:00',
                  closeTime: '20:30',
                  checkOnline:true,
                  checkInHouse:false,
                  tablesSelected:[ 10, 13, 15 ],
                  action:"Accept Booking",
                },
                {
                  name: 'Sun',
                  valid:{"from":"01/10/2021","to":"30/11/2021"},
                  daysSelected:[6],
                  openTime: '14:00',
                  closeTime: '20:30',
                  checkOnline:true,
                  checkInHouse:false,
                  tablesSelected:[ 10, 13, 15 ],
                  action:"Accept Booking",
                },
                {
                  name: 'Mon, Tue, Wed, Thu, Fri, Sat, Sun',
                  valid:{"from":"01/12/2021","to":"21/10/2030"},
                  daysSelected:[0,1,2,3,4,5,6],
                  openTime: '10:00',
                  closeTime: '23:00',
                  checkOnline:true,
                  checkInHouse:false,
                  tablesSelected:[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22],
                  action:"Send Request",
                },
              ]

    }
  },
  props:{

  },

  watch:{

  },
  methods:{
openDialog(item){
  this.$refs.availabilityEdit.item = item
  this.$refs.availabilityEdit.dialog = true
  this.$refs.availabilityEdit.daysSelected = item.daysSelected
  this.$refs.availabilityEdit.time_open = item.openTime
  this.$refs.availabilityEdit.time_close = item.closeTime


}

  },
  computed:{

  },
}
</script>
