<template>
  <v-App>




      <navBarLeft ref="navBarLeft"></navBarLeft>


    <v-main class="main-bcg" style="margin-left:55px;">

  <router-view/>


    </v-main>

  </v-App>
</template>

<script>

import navBarLeft from '@/components/nav/navBarLeft';



export default {
  name: 'AppWireframe',
  data() {
    return {
//isDrawerOpen:true
    }
  },
  components:{
    navBarLeft,


  },
  computed:{

    },
    methods:{




  },
}
</script>

<style>
.nb-shortcut{    display: block;
    position: absolute;
    background: red;
    padding: 5px;}
.main-bcg{background: #e6e6e6}
.navbarleft{
  width:100px;
  background:grey;
}

.body{
    background-color:#f9f9f9;
    min-height:98vh;
    margin:1vh;
}


</style>
