<template>


    <v-dialog v-model="dialog" max-width="250" >
      <v-card>
        <v-progress-linear
        indeterminate
          :active="loading"
        color="primary"
      ></v-progress-linear>
        <v-card-title class="headline">
          <v-icon class="pr-2" color="primary">mdi-vector-intersection</v-icon>
          Section
        </v-card-title>

        <v-card-text>

          <v-row>
            <v-col cols="12">


         </v-col>
        </v-row>

          <v-row>
            <v-col cols="12">
          <v-text-field
            v-model="data.locationName"
           label="Section Name"
           ></v-text-field>
         </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
        <v-text-field
          v-model="data.position"
         label="Position"
         ></v-text-field>
       </v-col>
      </v-row>







        </v-card-text>
        <v-card-actions>

          <v-spacer></v-spacer>
          <v-btn color="primary" text type="submit" @click="save">
            Save
          </v-btn>
          <v-btn color="grey" text type="submit" @click="close">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

</template>




<script>

export default {
  data() {
    return {
      loading:false,
      dialog:false,

        data:{},




    }},
  name: 'tableLayoutLocations',
  created() {

    },
  components: {


  },
  props:{

  },
  computed: {


  },
  methods:{
    close(){
      this.dialog = false
    },

    save(){
      this.$parent.updateLocation(this.data)
     this.dialog = false
    },




  },
}
</script>


<style>
</style>
