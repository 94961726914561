<template>
<div class="body">
<tabTopNav ref="availabilityTopNav" :headers="tabHeaders"></tabTopNav>

<v-tabs-items v-model="tab">
  <v-tab-item>
  <v-card flat color="#f9f9f9">
  <v-card-text ><tablesLayout ref="tablesLayout"></tablesLayout></v-card-text>

  </v-card>
  </v-tab-item>
</v-tabs-items>


</div>

</template>

<script>
// @ is an alias to /src
import tabTopNav from '@/components/nav/tabTopNav';
import tablesLayout from '@/components/tabs/tablesLayout';



export default {
  name: 'TableLayout',
  components: {
    tabTopNav,
    tablesLayout

  },
  data(){
    return{
      tab:[],
      tabHeaders:['Layout']
    }
  },
  watch:{
    refreshKey(){
      this.$refs.tablesLayout.fetchData()
    },
  },
  computed:{
    refreshKey(){
      return this.$store.state.refreshKey
    },
  },
  methods:{

  },
}
</script>
<style>



</style>
