<template>

  <v-dialog
    v-model="dialog"
    max-width="450"
  >

    <v-card>

      <v-card-title class="headline">
        <v-icon class="pr-2" color="primary">mdi-account-circle-outline</v-icon>
      {{data.surname}} {{data.name}}
      </v-card-title>


      <v-card-text class="text-left">
        <v-divider></v-divider>

<v-container class="d-flex align-start flex-row flex-wrap justify-space-between">
  <v-sheet>

    <v-col class="text-center">
      <v-icon color="primary">mdi-alpha-t-box-outline</v-icon><br>
      <div style="max-width:100px;">{{data.loca}}</div>
      <div class="mt-n1 text-h6 black--text">#{{data.table}}</div>
    </v-col>
  </v-sheet>
<v-sheet>
  <v-col class="text-center">
    <v-icon color="primary">mdi-account-multiple-outline</v-icon><br>
    <span>Guests</span>
    <div class="mt-n1 text-h6 black--text">{{data.guests}}</div>
  </v-col>
</v-sheet>
<v-sheet>
  <v-col class="text-center">
    <v-icon color="primary">mdi-clock-outline</v-icon><br>
    <span>Time</span>
    <div class="mt-n1 text-h6 black--text">{{data.time}}</div>
  </v-col>
</v-sheet>
<v-sheet>
  <v-col class="text-center">
    <v-icon color="primary">mdi-clock-outline</v-icon><br>
    <span>Duration</span>
    <div class="mt-n1 text-h6 black--text">{{data.dura}} min.</div>
  </v-col>
</v-sheet>
</v-container>





<v-divider class="mb-4"></v-divider>
  <strong class="v-list-item__title"><v-icon color="primary" class="pr-2">mdi-clipboard-outline</v-icon>TAGS / NOTES</strong>

<div class="mb-4 mt-2 pa-3">
    <template v-for="(tags,index) in data.tags" >


      <v-chip outlined class="mb-2 mr-1 text-body-1" :key="index" >

        <v-icon left small>
          {{tags.icon}}
        </v-icon>
        {{tags.value}}
      </v-chip>

    </template>
  </div>


    <v-divider class="mb-4"></v-divider>
    <strong class="v-list-item__title"><v-icon color="primary" class="pr-2">mdi-account-cog-outline</v-icon>EXTRA OPTIONS</strong>
<div class="mb-1 mt-2 pa-3">
    <template v-if="data.tags">
        <v-switch v-model="data.tags[0].seated"
        inset
      color="primary"
      label="Mark booking as Seated / Arrived"
        @change="updateTags"
      ></v-switch>
    </template>
</div>


    </v-card-text>



  <v-card-actions class="d-flex flex-wrap elevation-5">



      <v-btn
        color="primary"
        text
        @click="moveItem(data,'move')"
      >
      <v-icon class="pr-1">mdi-drag-variant</v-icon>
        Move
      </v-btn>
      <v-btn
        color="primary"
        text
        @click="moveItem(data,'copy')"
      >
      <v-icon class="pr-1">mdi-content-copy</v-icon>
        Copy
      </v-btn>
      <v-spacer></v-spacer>

      <v-btn
        color="primary"
        text
        @click="editDialog"
      >
        Edit
      </v-btn>

      <v-btn
        color="grey"
        text
        @click="dialog = false"
      >
        Close
      </v-btn>
    </v-card-actions>




    </v-card>
  </v-dialog>



</template>




<script>


export default {
  data() {
    return {
      dialog:false,
      data:[],
      line:[],
    TimelineItemDetailsData:[]
    //dialog: true,

    }},
  name: 'TimelineItemPreview',
  created() {

    },
  components: {


  },
  props:{

  },
  computed: {


  },
  methods:{
    updateTags(){
      let tags = {'system':{'seated':this.data.tags[0].seated}}
      let payload = {'time':this.data.timestamp,
                      'itemId': this.data.id,
                    'tags':tags}

      this.$parent.sendChangeTagsAxios(payload);


    },

    editDialog(){

      this.dialog = false
      this.$parent.timelineItemEditorDialog = true
      this.$parent.$refs.timelineItemEditor.isEditingMode = true

      this.$parent.$refs.timelineItemEditor.isSeated = false


      //this.$parent.currentSelectedTableSeats = {'seats_min':this.line.seats_min,'seats_max':this.line.seats_max,}
      //this.$parent.$refs.timelineItemEditor.data.guests = this.createSelected(1,4,6)


      this.$parent.getItemDataAxios(this.data)

    },
moveItem(data,action){

  this.dialog = false
  this.$parent.moveAction = action
  this.$parent.moveMode = true
  this.$parent.editMode = true
  this.$parent.currentOpenItemId = data.id
  this.$parent.currentSelectedOldTime = data.timestamp



},



  },


}
</script>


<style>
</style>
