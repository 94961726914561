<template>
  <div>
    <v-overlay
          :value="loading"
          opacity="0.9"
          color="#f9f9f9"
        >
    <div class="loader">
      <v-progress-circular
          indeterminate
            color="primary"

          size="64"
        ></v-progress-circular>
    </div>
  </v-overlay>

    <timelineTopMenu :currentViewTime="currentViewTime"></timelineTopMenu>



<div class="tl tl-scroll-table text-left"  :style="{'max-height': $vuetify.breakpoint.height - 60 + 'px'}">
  <table class="table noselect" :style="{width:tableWidth+130+'px','margin':'0 auto'}" >
    <thead class="th-top">

      <tr>
        <th class="time">
          <span class="empty"></span>
          <div v-for="(time,i) in timeline" :key="i" :class="time.class">
            {{time.time}}
          </div>
        </th>
      </tr>
    </thead>
    <tbody v-for="(section,i) in data.timeline" :key="i">
      <tr>
        <td class="sep"><span class="stcky">{{section.loca}}</span>
        </td>
      </tr>
      <template v-for="line in section.lines">
        <v-lazy :options="{threshold: .1  }" :key="line.key" class="spacer">
          <tr>
            <td class="fs13 spacer stky-hder tl-grey">
              Table
              <br>
              <span class="tbno">{{line.table}}</span>
              <br>
              <v-icon class="tl-grey" small>mdi-account-outline</v-icon>
              <br> {{line.seats}}
            </td>
            <td class="emptycoll"><div class="empty-spacer"></div></td>
            <td class="rw"
            :class="editMode ? ['rw-edit-active']:['']"


            :style="[editMode ? {'background-color':$vuetify.theme.themes.light.washed, width:tableWidth+60+'px'}:{ width:tableWidth+60+'px'}]"

            @click="addNewItem(line,section)">

              <timelineItem :thisline="line" :loca="section.loca" @openItem="openItem"></timelineItem>

              <ModalTimeLineItemEditor :dialog="ModalTimeLineItemEditor"></ModalTimeLineItemEditor>

            </td>
          </tr>
        </v-lazy>
      </template>
    </tbody>
  </table>
</div>

<ModalTimeLineItemEditor ref="ModalTimeLineItemEditor"></ModalTimeLineItemEditor>

<timelineItemPreview ref="timelineItemPreview"></timelineItemPreview>
<timelineAddNewItem ref="timelineAddNewItem"></timelineAddNewItem>
<timelineItemEditor ref="timelineItemEditor"></timelineItemEditor>
<timelineMoveItem ref="timelineMoveItem"></timelineMoveItem>
<timelineDeleteItem ref="timelineDeleteItem"></timelineDeleteItem>


</div>
</template>
<script>
import axios from 'axios';
import timelineItem from '@/components/timeline/timelineItem';
import timelineTopMenu from '@/components/timeline/timelineTopMenu';
import timelineItemPreview from '@/components/modals/timelineItemPreview';
import timelineAddNewItem from '@/components/modals/timelineAddNewItem';
import timelineMoveItem from '@/components/modals/timelineMoveItem';
import timelineItemEditor from '@/components/modals/timelineItemEditor';
import timelineDeleteItem from '@/components/modals/timelineDeleteItem';
//import ModalTimeLineItemEditor from '@/components/modals/TimelineItemEditor/Layout';
import ModalTimeLineItemEditor from '@/components/modals/old_timelineItemPreview_old'




export default {
  name: 'timelineTable',

  components:{
    timelineItem,
    ModalTimeLineItemEditor,
    timelineItemPreview,
    timelineAddNewItem,
    timelineTopMenu,
    timelineItemEditor,
    timelineMoveItem,
    timelineDeleteItem

  },
  data() {
    return {
      ModalTimeLineItemEditor:false,
      loading:false,
      editMode:false,
      moveMode:false,
      isHovering:false,
      timelineItemEditorDialog:false,
      currentViewTime:null,
      currentSelectedTable:null,
      currentOpenItemId:null,
      currentSelectedTableName:null,
      //currentSelectedNewTime:null,
      currentSelectedOldTime:null,
      currentSelectedTableSeats:null,


      data:{
  "timeline": [
    {
      "loca": "",
      "start": 540,
      "end": 1320,
      "locaId": 1,
      "lines": [
        {
          "table": 0,
          "tableId": 1,
          "seats": "0-0",
          "items": [
            {
              "id": 1,
              "name": "",
              "time": "",
              "dura": 0,
              "start": 0,
              "tags": [
              ]
            },

          ]
        },
      ]
    }
  ]
},
}
  },
  created() {
    const interval = 1000 * 60 * 60 * 24;
    let startOfDay = Math.floor(Date.now() / interval) * interval
    this.fetchData(  startOfDay/1000 )
  },
  computed:{
    tableWidth(){
      let start = 0
      let end = 0

      if(this.data.timeline){
        start = this.data.timeline[0].start
        end = this.data.timeline[0].end
      }
      return 2*(end - start) + 90
    },
    timeline () {
      let array=[]
      let start = this.data.timeline[0].start
      let end = this.data.timeline[0].end + 60 //added extra hour

      let startHours = (start - start%60) / 60
      let pieces = (end - start) / 60
      if(start%60 > 0) pieces += 1
      for (var i = 0; i < pieces; i++) {
        if(start%60 == 0){
          array.push({"class":"", "time":startHours + i +":00"})
          array.push({"class":"small", "time":startHours + i +":30"})
        }else{
          array.push({"class":"small", "time":startHours + i  +":30"})
          if(i < (pieces )) array.push({"class":"", "time":startHours + i + 1 +":00"})
        }
      }
      return array
    },
  },
  methods:{
    openItem(val){




      let itemDialog = this.$refs.ModalTimeLineItemEditor
      itemDialog.dialog = true
      itemDialog.data = val.payload
      itemDialog.data.loca = val.line.loca

      this.currentSelectedTableSeats = {'seats_min':val.line.seats_min,'seats_max':val.line.seats_max}


      itemDialog.line = val.line.loca
      itemDialog.data.table = val.line.table
      //itemDialog.data.loca = this.section.loca

    },
addNewItem(table,section){
  //table.tableId

  let pos = Math.floor((event.offsetX ) / 60)
  let currentPos = this.timeline[pos]
  this.currentSelectedTable = table.tableId
  this.currentSelectedTableName = table.table
  this.currentSelectedSectionName = section.loca
  this.currentSelectedTableSeats = {'seats_min':table.seats_min,'seats_max':table.seats_max}
  //this.currentSelectedNewTime = this.calcCurrentTimestamp(this.currentViewTime,currentPos.time)
  //console.log(this.calcCurrentTimestamp(this.currentViewTime,currentPos.time));


//console.log(table.tableId,this.currentViewTime,this.currentOpenItemId);


  if(this.editMode == true && this.moveMode == false){
        this.$parent.$refs.timelineTable.$refs.timelineAddNewItem.dialog = true
        this.$parent.$refs.timelineTable.$refs.timelineAddNewItem.time = currentPos
  }
  //Move item somewhere
  if(this.editMode == true && this.moveMode == true){
    //console.log(this.$refs.timelineMoveItem.newTime);
      this.$parent.$refs.timelineTable.$refs.timelineMoveItem.dialog = true
      this.$parent.$refs.timelineTable.$refs.timelineMoveItem.time = currentPos

  }

},

  fetchData(time){

    //let token = this.$store.getters.tokenAuthServer.access_token
    //let headers = { token: `${token}` };
    //time = Math.round(+new Date()/1000)

    this.currentViewTime = time

    this.loading = true
    let that = this
    let apiUrl = this.$store.state.apiUrl



    //let token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC8xOTIuMTY4LjAuMTk6ODAwMFwvYXBpXC9sb2dpbiIsImlhdCI6MTYxNDg5MDQzMCwiZXhwIjoxNjE0ODk0MDMwLCJuYmYiOjE2MTQ4OTA0MzAsImp0aSI6IlRjVjIwYTdPc3N0N2VIY2siLCJzdWIiOjMsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.dvlI0sDvdI2KLKFF_LOhIKBXn3jo_fCS0ZmvGqFIwM0'

    //axios.get(`json/timeline.json`)
    //axios.get(`https://matej.co.uk/axios/heisen/timetable.php`)
    //axios.get(`http://192.168.0.19:8080/json/timeline.json`)
const url = apiUrl+'/timeline?viewTime=' + time + '&estaActive=' + this.$store.getters.estaActive;
//const token = this.$store.getters.tokenAuthServer.access_token;
// const options = {
//   method: 'GET',
//   headers: {
//     'Authorization' : `Bearer ${token}`,
//     'Accept': 'application/json',
//     'Content-Type': 'application/json;charset=UTF-8'
//   },
// }
  //fetch(url, options)
  axios.get(url,{'viewTime':time})
        .then(async response => {
          //const data = await response.json();
          if(response.status == 200 && response.data.status == 'success'){
          that.data.timeline = this.createListItems(response.data)
          that.data.tagNames = that.filterPublicTags(response.data.taglist)
          that.$store.dispatch('updateDiaryData',response.data.diary)
          that.$store.dispatch('updateFormsData',response.data.forms)
          const formDataMaster =  JSON.parse(JSON.stringify(response.data.forms))
          that.$store.dispatch('updateFormsDataMaster',formDataMaster)
          //console.log(this.createListItems(response.data))
         that.loading = false
       }else{
         console.warn('Status: '+response.status+' Response: '+ response.data.status );
       }
        })
        .catch(function () {});

  },
  sendMoveItemAxios(newTimePos){

    let apiUrl = this.$store.state.apiUrl
    this.$refs.timelineMoveItem.isLoading = true
    let that = this
    let payload = { 'viewTime': this.currentViewTime,
                //'newTime':this.currentSelectedNewTime,
                'newTime':this.calcCurrentTimestamp(this.currentViewTime,newTimePos.time),
                'oldTime':this.currentSelectedOldTime,
                    'newTable':this.currentSelectedTable,
                    itemId:this.currentOpenItemId
            }
    axios.put(apiUrl+`/booking-move`,payload)
        .then(response => {
          if(response.status == 200 && response.data.status == 'success'){

              that.data.timeline = this.createListItems(response.data)
              that.$refs.timelineMoveItem.dialog = false
              that.$refs.timelineMoveItem.isLoading = false
              that.editMode = false,
              that.moveMode = false,


         that.loading = false
       }else{
         console.warn('Status: '+response.status+' Response: '+ response.data.status );
       }
        })

  },
  sendPasteItemAxios(newTimePos){

    let apiUrl = this.$store.state.apiUrl
    this.$refs.timelineMoveItem.isLoading = true
    let that = this
    let payload = { 'viewTime': this.currentViewTime,
                //'newTime':this.currentSelectedNewTime,
                'newTime':this.calcCurrentTimestamp(this.currentViewTime,newTimePos.time),
                'oldTime':this.currentSelectedOldTime,
                    'newTable':this.currentSelectedTable,
                    itemId:this.currentOpenItemId
            }
    axios.put(apiUrl+`/booking-paste`,payload)
        .then(response => {
          if(response.status == 200 && response.data.status == 'success'){

              that.data.timeline = this.createListItems(response.data)
              that.$refs.timelineMoveItem.dialog = false
              that.$refs.timelineMoveItem.isLoading = false
              that.editMode = false,
              that.moveMode = false,


         that.loading = false
       }else{
         console.warn('Status: '+response.status+' Response: '+ response.data.status );
       }
        })

  },

  sendChangeTagsAxios(payload){

    let apiUrl = this.$store.state.apiUrl



    axios.put(apiUrl+`/booking-changetags`,payload)
        .then(response => {
          if(response.status == 200 && response.data.status == 'success'){
            console.log()
       }else{
         console.warn('Status: '+response.status+' Response: '+ response.data.status );
       }
        })

  },

  createNewItemAxios(payload){
    let apiUrl = this.$store.state.apiUrl
    this.$refs.timelineItemEditor.isLoading = true
    let that = this

    axios.post(apiUrl+`/booking`,payload)
        .then(response => {
          if(response.status == 200 && response.data.status == 'success'){

              that.data.timeline = this.createListItems(response.data)
              that.$refs.timelineItemEditor.dialog = false
              that.$refs.timelineItemEditor.isLoading = false
              that.editMode = false,
              that.moveMode = false,


         that.loading = false
       }else{
         console.warn('Status: '+response.status+' Response: '+ response.data.status );
       }
        })
  },

  updateItemAxios(payload){
    let apiUrl = this.$store.state.apiUrl
    this.$refs.timelineItemEditor.isLoading = true
    let that = this

    axios.put(apiUrl+`/booking`,payload)
        .then(response => {
          if(response.status == 200 && response.data.status == 'success'){

              that.data.timeline = this.createListItems(response.data)
              that.$refs.timelineItemEditor.dialog = false
              that.$refs.timelineItemEditor.isLoading = false
              that.editMode = false,
              that.moveMode = false,


         that.loading = false
       }else{
         console.warn('Status: '+response.status+' Response: '+ response.data.status );
       }
        })
  },

  removeItemAxios(payload){
    let apiUrl = this.$store.state.apiUrl
    this.$refs.timelineItemEditor.isLoading = true
    let that = this

    axios.post(apiUrl+`/removeBooking`,payload)
        .then(response => {
          if(response.status == 200 && response.data.status == 'success'){

              that.data.timeline = this.createListItems(response.data)
              that.$refs.timelineItemEditor.dialog = false
              that.$refs.timelineItemEditor.isLoading = false
              that.editMode = false,
              that.moveMode = false,


         that.loading = false
       }else{
         console.warn('Status: '+response.status+' Response: '+ response.data.status );
       }
        })
  },



  getItemDataAxios(data){
    //this.$refs.timelineMoveItem.isLoading = true
    this.$refs.timelineItemEditor.isLoading = true
    let apiUrl = this.$store.state.apiUrl
    let that = this
    //let payload = {'itemId':1}
    axios.get(apiUrl+`/booking?itemId=`+data.id)
        .then(response => {
          if(response.status == 200 && response.data.status == 'success'){
            that.$refs.timelineItemEditor.isLoading = false
          that.$refs.timelineItemEditor.data = response.data.item


          let seats = that.currentSelectedTableSeats
          that.$refs.timelineItemEditor.data.guests = this.createSelected(response.data.item.guests,seats.seats_min,seats.seats_max)
          that.$refs.timelineItemEditor.data.loca = data.loca
          that.$refs.timelineItemEditor.data.table = data.table
          that.$refs.timelineItemEditor.data.time = data.time



         that.loading = false
       }else{
         console.warn('Status: '+response.status+' Response: '+ response.data.status );
       }
        })

  },



  filterPublicTags(taglist){
    let tagNames = []
    for (var i = 0; i < taglist.length; i++) {
      if(taglist[i]['icon'] == "")tagNames.push(taglist[i]['description'])
    }

    return tagNames
  },
  calcCurrentTimestamp(timestamp, time){
    let beginOfDay = timestamp - (timestamp % 86400);
    let hhmm = time.split(':');

    return beginOfDay + ( hhmm[0] * 3600 ) + ( hhmm[1] * 60 )
  },


  createPayload(payload,taglist){
    for (var i = 0; i < payload.tags.length; i++) {
      var newtag = taglist.filter(x => x.id === payload.tags[i]['id']);
      payload.tags[i]['desc']=newtag[0].desc
      payload.tags[i]['icon']=newtag[0].icon
    }
    return payload
  },

  createSelected(selected,min,max){

    let arr = []
    for (var i = min; i < max + 1; i++) {
      arr.push(i)
    }
    return {'selected':selected,'list':arr}
  },




createElements(local,list,taglist){

 let elements=[]
 let currentPos =  0
 let previousPos = 0
 let overlap=0
 let warning = false


   list.sort((a, b) => (a.start > b.start) ? 1 : -1)
   for (var i = 0; i < list.length; i++) {
     if(list[0]['start'] != local.start && i == 0){
       //first element is empty
              previousPos += list[0]['start'] - local.start
              overlap = previousPos


}else{
     currentPos = list[i]['start'] - local['start'] - previousPos
     overlap += currentPos
     if(currentPos < 0){warning=true}else{warning=false}
}

     elements.push({"previous":currentPos, "overlap":overlap,"id":list[i]["id"],
           "payload":this.createPayload(list[i],taglist),
           "warning":warning,
           "lenght":list[i]['dura']})
         previousPos += list[i]['dura'] + currentPos

   }

 return elements

},
  createListItems(data){

    let thisresponse = data.timeline
    let temparray = []
  //  let items = []
   for (var loc = 0; loc < thisresponse.length; loc++) {
     //loca
     for (var lin = 0; lin < thisresponse[loc]['lines'].length; lin++) {
       //lines
       for (var ite = 0; ite < thisresponse[loc]['lines'][lin]['items'].length; ite++) {

           temparray.push(thisresponse[loc]['lines'][lin]['items'][ite])
       }
      thisresponse[loc]['lines'][lin]['elements'] = this.createElements(thisresponse[loc],temparray,data.taglist) //inject elements
      temparray = []

     }
   }

   return thisresponse
 },
  },
}

</script>
<style>


.table{border-spacing: 0px;border-right: 1px solid #e2e2e2;}
.loader{height:5px!important;}
.tl .time div{text-align:center;font-size:14px;display: inline-block;width:60px;border-right: 1px solid #efeeee;}
.tl .time .empty{display: inline-block;width:75px;}
.tl .emptycoll .empty-spacer{width:22px;}
.tl .emptycoll{background: #f9f9f9;border-bottom: 1px solid #f2f2f2;}
.tl .time .small{font-weight: normal;}

.tl .sep{    background: #fff url(/images/grid-line.svg);
    border-bottom: 1px solid #e2e2e2;
    border-left: 1px solid #e2e2e2;
    font-weight: bold;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 4px;
    padding: 5px;
    padding-top: 8px;}
.tl .fs13{font-size: 13px;}
.tl-grey{color: #78909c !important;}
.tl .th-top th {
  padding-top: 5px;
  padding-bottom: 5px;

  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 3;
  background: white;
 border-top: 1px solid #e2e2e2;
  border-left: 1px solid #e2e2e2;
   border-bottom: 2px solid #e2e2e2;

}
.tl .tbno{color:#2C3E40;font-size:15px;font-weight:bold;}
.tl .rw-edit-active{
transition: background-color 100ms linear;}
.tl .rw-edit-active:hover{cursor:pointer;
  -webkit-filter: brightness(92%);

transition: 100ms linear;
     }
.tl .rw{border-bottom:1px solid #929292;
 background: #fbfbfb url(/images/grid-v-line.svg);}
 .tl .rw:hover{background-color: #f4f4f4}
.tl .rw-hdr{background-color: red;}
.tl .spacer{height:90px;}
.tl .stcky{position: sticky;
    position: -webkit-sticky;
    left: 5px;

    display: inline-block;}
.tl .stky-hder{
  text-align: center;
  vertical-align: top;
    position: sticky;
    position: -webkit-sticky;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 8px;
    left: 0;
    top: auto;
    z-index: 2;
    background: #ffffff;
    border-right: 1px solid #c1c1c1;
    border-bottom: 1px solid #c1c1c1;
      border-left: 1px solid #e2e2e2;

}

.tl-scroll-table {

  overflow: auto;
  -webkit-overflow-scrolling: touch;
   overscroll-behavior-y: contain;
   overscroll-behavior-x: contain;
  border-spacing: 0px;
  padding: 0px;
  border-collapse: separate;
}

.tl .noselect {
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
</style>
