<template>

  <v-dialog
    v-model="dialog"
    max-width="340"
  >

    <v-card>

      <v-card-title class="headline">
        <template v-if="!isCancel && !isNoShow">
            <v-icon class="pr-2" color="red">mdi-close-octagon-outline</v-icon>
            Remove
        </template>

        <template v-if="isNoShow">
            <v-icon class="pr-2" color="orange">mdi-eye-off-outline</v-icon>
            No Show
        </template>

        <template v-if="isCancel && !isNoShow">
            <v-icon class="pr-2" color="orange">mdi-eye-off-outline</v-icon>
            Cancel
        </template>

      </v-card-title>

      <v-card-text>

      <v-container v-if="isNoShow">
      The No Show will be recorded in the file.<br>
      You can disable following Booking Sources for this user.
      <v-divider class="mt-3"></v-divider>
        <v-switch
      v-model="switchOnline"
      color="primary"
      label="Over The Phone"
    ></v-switch>
    <v-switch
  v-model="switchPhone"
  color="primary"
  label="Online Booking"
></v-switch>

    </v-container>

<v-container class="mb-n5">
  <v-textarea
         outlined
         v-model="comment"
         label="Leave comment (optional)"
       ></v-textarea>
</v-container>
<v-container v-if="!isNoShow">
Are you sure you want to remove this booking?
</v-container>
      </v-card-text>




    <v-card-actions>
      <v-spacer></v-spacer>


      <v-btn
        color="primary"
        text
        @click="removeItem"
      >
        confirm
      </v-btn>

      <v-btn
        color="grey"
        text
        @click="dialog = false"
      >
        Close
      </v-btn>
    </v-card-actions>




    </v-card>
  </v-dialog>



</template>




<script>

export default {
  data() {
    return {
      comment:null,
      dialog:false,
      isCancel:false,
      isNoShow:false,
      switchOnline:true,
      switchPhone:true,
      bookingId:null,
      start_at:null,

    }},
  name: 'timelineDeleteItem',
  created() {

    },
  components: {


  },
  watch:{

  },
  props:{

  },
  computed: {


  },
  methods:{
    removeItem(){
      this.$parent.removeItemAxios({'comment':this.comment,'allowPhone':this.switchPhone,'allowOnline':this.switchOnline,'start_at':this.start_at,'isNoShow':this.isNoShow,'isCanceled':this.isCancel,'id':this.bookingId})
      this.dialog = false
      this.isCancel = false
      this.comment = null
    }
  }
}
</script>


<style>
</style>
