<template>
  <div>
    <v-snackbar
          v-model="openSnackbar"
            :timeout="timeout"
        >
        <v-icon>{{icon}}</v-icon>
        {{text}}

          <template v-slot:action="{ attrs }">
            <v-btn
              text
              v-bind="attrs"
              @click="openSnackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
  </div>
</template>

<script>
export default {
  name: 'onlineChecker',
  data() {
    return {
      onLine: navigator.onLine,
      openSnackbar: false,
      text: 'Trying to connect...',
      icon: 'mdi-alert',
      timeout:-1,
    }
  },
  props: {

  },
  watch:{
    onLine(val){
      this.openSnackbar = true
      if(val === false){
        this.icon = 'mdi-alert'
        this.text = 'Trying to connect...'
        this.timeout = -1
      }
      if(val === true){
        this.icon = 'mdi-earth'
        this.text = 'Device Online'
        this.timeout = 3000
      }
    },
  },
  mounted() {
   window.addEventListener('online',  this.updateOnlineStatus);
   window.addEventListener('offline', this.updateOnlineStatus);
 },
  methods:{
  updateOnlineStatus(e){
    if(e.type === 'offline') this.onLine = false
    if(e.type === 'online') this.onLine = true
  },
  },
  beforeDestroy() {
   window.removeEventListener('online',  this.updateOnlineStatus);
   window.removeEventListener('offline', this.updateOnlineStatus);
 }
}
</script>
<style>
</style>
