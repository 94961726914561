<template>
  <v-dialog
  persistent
  scrollable
    v-model="dialog"
    :max-width="$vuetify.breakpoint.xs ? 330 : '80vh'"
  >
    <v-card v-if="!addTask">



      <v-card-title class="headline">
        <v-icon class="pr-2" color="primary">mdi-calendar</v-icon>
      Today's Tasks
      </v-card-title>


      <v-card-text class="text-left">


 <v-tabs
      v-model="tabs"
      background-color="blue-grey lighten-5"
      grow
    >
    <v-tab><v-icon small class="pr-1">mdi-sticker-alert-outline</v-icon>Open Tasks</v-tab>
    <v-tab><v-icon small class="pr-1">mdi-sticker-check-outline</v-icon>Completed</v-tab>
</v-tabs>


<v-tabs-items v-model="tabs">
<v-tab-item style="min-height:300px" class="pt-4">
<table class="diary-table">
  <thead>
    <tr class="text--primary">
      <td style="width:50px;">
        Time
      </td>
      <td style="width:50px;">
        Done
      </td>
      <td>
        Task
      </td>
    </tr>
  </thead>
  <tbody>
    <template v-for="task in diaryData"  :class="[task.done ? ['text-done']:['text-todo']]">
      <tr :key="task.key" v-if="task.done == false">
      <td class="pr-4">
      {{task.display_time}}
      </td>
      <td class="pr-4">
        <template v-if="removeTask">
          <v-btn text color="red" @click="removeThisTask(task)">
            <v-icon>mdi-close-outline</v-icon>
          </v-btn>
        </template>
              <template v-if="!removeTask">
            <v-switch v-model="task.done"

            @click="changeTask(task)"
          color="primary"
          label=""

          ></v-switch>
            </template>
      </td>
      <td>
        {{task.description}}
        <v-row class="pt-2 grey--text text-caption">
          <v-col cols="6" v-if="task.set_by_name"><v-icon small color="grey" class="pr-2">mdi-account</v-icon>Set by {{task.set_by_name}}</v-col>
          <v-col cols="6" v-if="task.done_by_name"><v-icon small color="primary" class="pr-2">mdi-account-check-outline</v-icon>Completed by {{task.done_by_name}}</v-col>
        </v-row>
      </td>
    </tr>
    </template>
  </tbody>
  </table>
</v-tab-item>
<v-tab-item style="min-height:300px" class="pt-4">
  <table class="diary-table">
    <thead>
      <tr class="text--primary">
        <td style="width:50px;">
          Time
        </td>
        <td style="width:50px;">
          Done
        </td>
        <td>
          Task
        </td>
      </tr>
    </thead>
    <tbody>
      <template v-for="task in diaryData"  :class="[task.done ? ['text-done']:['text-todo']]">
        <tr :key="task.key" v-if="task.done == 1">
        <td class="pr-4">
        {{task.display_time}}
        </td>
        <td class="pr-4">
          <template v-if="removeTask">
            <v-btn text color="red" @click="removeThisTask(task)">
              <v-icon>mdi-close-outline</v-icon>
            </v-btn>
          </template>
                <template v-if="!removeTask">
                  <v-switch v-model="task.done"

                  @click="changeTask(task)"
                color="primary"
                label=""

                ></v-switch>
              </template>
        </td>
        <td>
          {{task.description}}
          <v-row class="pt-2 grey--text text-caption">
            <v-col cols="6" v-if="task.set_by_name"><v-icon small color="grey" class="pr-2">mdi-account</v-icon>Set by {{task.set_by_name}}</v-col>
            <v-col cols="6" v-if="task.done_by_name"><v-icon small color="primary" class="pr-2">mdi-account-check-outline</v-icon>Completed by {{task.done_by_name}}</v-col>
          </v-row>
        </td>
      </tr>
      </template>
    </tbody>
    </table>
  </v-tab-item>
</v-tabs-items>





<v-progress-linear
  :active="isLoading"
indeterminate
color="primary"
></v-progress-linear>

    </v-card-text>



    <v-card-actions class="d-flex flex-wrap elevation-5">
      <v-btn color="primary" text @click="resetNewTask();addTask = true">
        Add task
      </v-btn>
      <v-btn color="red" text @click="removeTask = !removeTask">
        Remove task
      </v-btn>
<v-spacer></v-spacer>
      <v-btn
        color="grey"
        text
        @click="dialog = false"
      >
        Close
      </v-btn>
    </v-card-actions>
    </v-card>


    <v-card v-if="addTask">

      <v-card-title class="headline">
        <v-icon class="pr-2" color="primary">mdi-calendar</v-icon>
      Add Tasks
      </v-card-title>
        <v-card-text>

          <div style="width:200px">
            <v-select
              v-model="newTask.display_time"
           :items="timeItems"
           filled
           label="Time"
         ></v-select>
        </div>

          <v-textarea outlined v-model="newTask.description" label="Task Description"
            :rules="[v => (v || '' ).length <= 250 || 'Description must be 250 characters or less']"
          ></v-textarea>




        </v-card-text>
      <v-card-actions class="d-flex flex-wrap elevation-5">

        <v-btn color="grey" text @click="addTask = false">
          <v-icon size="small" class="mr-2">mdi-arrow-left</v-icon>
           Back
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="saveTask">
           Save
        </v-btn>


      </v-card-actions>
      </v-card>


  </v-dialog>

</template>




<script>
import axios from 'axios';

  export default {

    name:"displayDiary",
    data () {
      return {
        tabs:null,
        dialog:false,
        addTask:false,
        removeTask:false,
        isLoading:false,

        newTask:{"done":false,"display_time":"","description":""},



      }
    },
    watch: {
      diaryNumber: function(val){
      this.$parent.diaryNumber = val
      }
    },

    computed: {
      timeItems(){
        let items = ["Anytime"]
        for (var i = 8; i <23; i++) {
          items.push(("0" + i).slice(-2) + ':00')
          items.push(("0" + i).slice(-2) + ':15')
          items.push(("0" + i).slice(-2) + ':30')
          items.push(("0" + i).slice(-2) + ':45')

        }
        return items
      },
      diaryData:{get(){
        let diary = this.$store.getters.diaryData
        return diary
      },
      set(val){
        this.$store.dispatch('updateDiaryData', val)
        }
      },

      diaryNumber(){
        let count = 0

        for (var i = 0; i < this.diaryData.length; i++) {
            if(this.diaryData[i].done == true) count += 1
        }

        return (this.diaryData.length) - count
      },
    },

    created(){

      this.$parent.diaryNumber = this.diaryNumber
      //let selDate = this.$parent.$refs.timelinePickDate.date
      //console.log(selDate);
    },



    methods: {

      sendNewtaskAxios(payload){
        this.isLoading = true
        let apiUrl = this.$store.state.apiUrl
        let that = this
        axios.put(apiUrl+`/diarytask`,payload)
            .then(response => {
              if(response.status == 200 && response.data.status == 'success'){
                console.log(response.data);
                payload.id = response.data.lastId
                that.diaryData.push(payload)
                that.isLoading = false
           }else{
             console.warn('Status: '+response.status+' Response: '+ response.data );
           }
            })
      },

      sendRemoveTaskAxios(payload){
          this.isLoading = true
          let that = this
        let apiUrl = this.$store.state.apiUrl
        axios.post(apiUrl+`/diarytask`,payload)
            .then(response => {
              if(response.status == 200 && response.data.status == 'success'){
                  that.isLoading = false
           }else{
             console.warn('Status: '+response.status+' Response: '+ response.data.status );
           }
            })
      },

      sendUpdateDoneTaskAxios(payload){

        let apiUrl = this.$store.state.apiUrl
        axios.post(apiUrl+`/diarytask_done`,payload)
            .then(response => {
              if(response.status == 200 && response.data.status == 'success'){
                console.log(response.data);
           }else{
             console.warn('Status: '+response.status+' Response: '+ response.data.status );
           }
            })
      },

      changeTask(task){
        if(task.done == true){task.done_by_name = this.$store.getters.userActive.name}
        if(task.done == false){task.done_by_name = ''}
        this.sendUpdateDoneTaskAxios({"itemId":task.id,"value":task.done})
      },

      removeThisTask(task){
        let diaryData = this.diaryData
        let newData = []
        for (var i = 0; i < diaryData.length; i++) {
          if(diaryData[i].id !== task.id) newData.push(diaryData[i])
        }
        this.diaryData = newData
        this.sendRemoveTaskAxios({"itemId":task.id})
        this.removeTask = false
      },



      resetNewTask(){
        this.newTask = {"done":false,"display_time":"Anytime","description":""}
      },
    saveTask(){

      //let diaryData = this.diaryData
      this.newTask.time = this.$parent.$parent.currentViewTime
     this.newTask.set_by_name = this.$store.getters.userActive.name
      this.sendNewtaskAxios(this.newTask)
      //diaryData.push(this.newTask)
      this.addTask = false
    },
    },
  }
</script>


<style scoped>
.text-todo{color:black}
.diary-table{
  min-width:100%;
  border-spacing: 0px;
    border-collapse: separate;
    font-size:1.1em;
}

.diary-table td{
  padding:5px;
}

.diary-table thead{
  font-weight: bold;
}

.diary-table tbody td{
  padding-bottom:15px;
  padding-top:15px;
  border-bottom:1px solid #e0e0e0;
}

</style>
