<template>
<div class=" body">
<tabTopNav ref="availabilityTopNav" :headers="tabHeaders"></tabTopNav>

<v-tabs-items v-model="tab">
  <v-tab-item>
  <v-card flat>
    <v-card-text >
      <availabilitySchedule ref="availabilitySchedule"></availabilitySchedule>
    </v-card-text>
  </v-card>
  </v-tab-item>
  <v-tab-item>
  <v-card flat>
    <v-card-text >(Online - Empty)</v-card-text>
  </v-card>
  </v-tab-item>
</v-tabs-items>


</div>

</template>

<script>
// @ is an alias to /src
import tabTopNav from '@/components/nav/tabTopNav';
import availabilitySchedule from '@/components/availability/availabilitySchedule';



export default {
  name: 'ShiftSetup',
  components: {
    tabTopNav,
    availabilitySchedule
  },
  data(){
    return{
      tab:[],
      tabHeaders:['Schedule','Exceptions']
    }
  },
  computed:{

  },
  methods:{

  },
}
</script>
<style>



</style>
