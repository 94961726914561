let mutations = {

//  updateBookingsData (state, payload) {
//     state.bookingsData = payload
//   },

  isUserAuthentificated (state, payload) {
     state.isUserAuthentificated = payload
   },

   tokenAuthServer (state, payload) {
      state.tokenAuthServer = payload
    },

quickLoginForm (state, payload) {
state.quickLoginForm = payload
},

axiosCache (state, payload) {
state.axiosCache = payload
},

userActive (state, payload) {
state.userActive = payload
},

estaGroup (state, payload) {
state.estaGroup = payload
},

diaryData (state, payload) {
state.diaryData = payload
},

formDataMaster (state, payload) {
state.formDataMaster = payload
},

formData (state, payload) {
state.formData = payload
},

currentFormData (state, payload) {
state.currentFormData = payload
},

configFile (state, payload) {
state.configFile = payload
},









//originalRequest (state, payload) {
//state.originalRequest = payload
//},

    estaActive (state, payload) {
       state.estaActive = payload
     },

refreshKey (state) {
state.refreshKey += 1
},



  }
 export default mutations
