var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"userSearch"},[_c('v-list-item',{staticClass:"serchResultItem",on:{"click":_vm.closeDialog}},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"color":"primary"}},[(_vm.resultSearch == null)?[_vm._v("mdi-magnify")]:[_vm._v("mdi-account-circle")]],2)],1),(_vm.resultSearch == null)?_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Guest Surname or Number")])],1):_c('v-list-item-content',[(!_vm.resultSearch.display_name)?_c('v-list-item-title',[_vm._v(_vm._s(_vm.resultSearch.name))]):_vm._e(),_c('v-list-item-title',[_vm._v(_vm._s(_vm.resultSearch.display_name))]),_c('v-list-item-subtitle',[_c('v-icon',{staticClass:"pr-2",attrs:{"x-small":"","color":"grey darken-1"}},[_vm._v("mdi-phone-in-talk-outline")]),_vm._v(_vm._s(_vm.resultSearch.phone_number))],1),_c('v-list-item-subtitle',[_c('v-icon',{staticClass:"pr-2",attrs:{"x-small":"","color":"grey darken-1"}},[_vm._v("mdi-home-city-outline")]),_vm._v(_vm._s(_vm.resultSearch.origin))],1)],1)],1),_c('v-fade-transition',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.dialog)?_c('v-card',{staticClass:"popup"},[_c('v-card-title',[_c('v-text-field',{staticClass:"ml-6",attrs:{"loading":_vm.isLoading,"hide-details":"","autofocus":"","full-width":"","label":"Search for a guest","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"mt-n8",attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pa-0"},[(!_vm.isAddNew)?_c('v-container',{staticClass:"list"},[(_vm.search)?_c('v-list-item',{staticClass:"mt-n3 ml-6 mr-6 primary rounded-lg",on:{"click":_vm.createNewCustomer}},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-plus ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"white--text font-weight-bold"},[_vm._v("Add \""+_vm._s(_vm.search)+"\" as a new customer")])],1)],1):_vm._e(),_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.searchModel),callback:function ($$v) {_vm.searchModel=$$v},expression:"searchModel"}},_vm._l((_vm.items),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-account-circle ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))]),_c('v-list-item-subtitle',[_c('v-icon',{staticClass:"pr-2",attrs:{"x-small":"","color":"grey darken-1"}},[_vm._v("mdi-phone-in-talk-outline")]),_vm._v(_vm._s(item.phone_number))],1),_c('v-list-item-subtitle',[_c('v-icon',{staticClass:"pr-2",attrs:{"x-small":"","color":"grey darken-1"}},[_vm._v("mdi-home-city-outline")]),_vm._v(_vm._s(item.origin))],1)],1)],1)}),1)],1):_vm._e(),(_vm.isAddNew)?_c('v-container',{staticClass:"pa-8"},[_c('div',{staticStyle:{"margin":"10px"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required|max:27"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"First Name","error-messages":errors},model:{value:(_vm.newGuest.name),callback:function ($$v) {_vm.$set(_vm.newGuest, "name", $$v)},expression:"newGuest.name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Last Name","rules":"required|max:27"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Last Name","error-messages":errors},model:{value:(_vm.newGuest.surname),callback:function ($$v) {_vm.$set(_vm.newGuest, "surname", $$v)},expression:"newGuest.surname"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Phone Number","rules":"required|digits_between:10,11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":11,"label":"Mobile Phone Number"},model:{value:(_vm.phone_number),callback:function ($$v) {_vm.phone_number=$$v},expression:"phone_number"}})]}}],null,true)})],1)]):_vm._e()],1),_c('v-card-actions',{staticClass:"d-flex flex-wrap elevation-5"},[_c('v-spacer'),(_vm.isAddNew)?_c('v-btn',{attrs:{"color":"primary","text":"","disabled":invalid},on:{"click":_vm.saveNewGuest}},[_vm._v(" Continue ")]):_vm._e(),(_vm.isAddNew)?_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.isAddNew = !_vm.isAddNew}}},[_vm._v(" Cancel ")]):_vm._e(),(!_vm.isAddNew)?_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")]):_vm._e()],1)],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }